import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const authToken = cookies.get("authorization");

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
    "Authorization": authToken
  }
});


import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import adminService from '../../services/admin.service';
import AdminUserView from '../../components/admin/AdminUserView.component';

/**
 * AllUsersComponent page displays a list of users with pagination and the ability to manage each user.
 * @returns {JSX.Element} The AllUsersComponent page.
 */
export default function AllUsers() {
  const [users, setUsers] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const usersPerPage = 8;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUsers();
  }, []);

  /**
   * Fetches the list of users from the server.
   */
  const fetchUsers = async () => {
    try {
      const response = await adminService.getUsers();
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  /**
   * Handles filter change and resets the current page to 1.
   * @param {string} filter - The selected filter.
   */
  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
    setCurrentPage(1);
  };

  /**
   * Handles the click event to open the user management modal.
   * @param {string} userId - The ID of the user to manage.
   */
  const handleEditClick = (userId) => {
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };

  /**
   * Closes the user management modal and resets the selected user ID.
   */
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (a.first_name < b.first_name) return -1;
    if (a.first_name > b.first_name) return 1;
    return 0;
  });

  const filteredUsers = sortedUsers.filter((user) => {
    if (currentFilter === 'All') return true;
    return user.company_role === currentFilter;
  });

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handlePreviousClick = () => {
    setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, totalPages));
  };

  const startResult = (currentPage - 1) * usersPerPage + 1;
  const endResult = Math.min(startResult + usersPerPage - 1, filteredUsers.length);

  return (
    <div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-2">
        <nav className="sm:hidden mt-5" aria-label="Back">
          <Link to="/admin" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Admin Home
          </Link>
        </nav>
        <nav className="hidden sm:flex mt-5" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <Link to="/admin" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Admin Home
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <Link to="/admin/all-users" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  All Users
                </Link>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 mt-5">
        <div className="rounded-lg bg-white px-5 py-6 shadow-lg border border-gray-100 sm:px-6">
          <div className="flex min-h-full flex-col">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-xl font-semibold text-gray-900 my-4">All Users</h3>
          </div>
            <div className="px-0">
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 table-fixed">
                      <thead>
                        <tr>
                          <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">User</th>
                          <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden md:table-cell">Contact</th>
                          <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Role</th>
                          <th scope="col" className="relative px-3 py-3 text-left text-sm font-semibold text-gray-900">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {currentUsers.length > 0 ? (
                          currentUsers.map((person) => (
                            <tr key={person.email} className="even:bg-gray-50">
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                                <div className="flex items-center">
                                  <div className="h-11 w-11 flex-shrink-0">
                                    <img className="h-11 w-11 rounded-full" src={person.image || '/images/blank-profile-picture.png'} alt="" />
                                  </div>
                                  <div className="ml-4">
                                    <div className="font-medium text-gray-900">{person.first_name + " " + person.last_name}</div>
                                    <div className="font-medium text-gray-900">{person.account_type}</div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
                                <div className="mt-1 text-gray-900">{person.email}</div>
                                <div className="mt-1 text-gray-500">{person.phone_number}</div>
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                                <span className="inline-flex items-left rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  {person.company_role}
                                </span>
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-left text-sm font-medium">
                                <Link to={"/admin/all-users/" + person.id} className="text-blue-500 hover:text-blue-700">View</Link>

                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                              No Users Yet
                            </td>
                          </tr>
                        )}
                        {currentUsers.length < usersPerPage && [...Array(usersPerPage - currentUsers.length)].map((_, index) => (
                          <tr key={`empty-${index}`} className="even:bg-gray-50">
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <nav
                      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                      aria-label="Pagination"
                    >
                      <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                          Showing <span className="font-medium">{startResult}</span> to <span className="font-medium">{endResult}</span> of{' '}
                          <span className="font-medium">{filteredUsers.length}</span> results
                        </p>
                      </div>
                      <div className="flex flex-1 justify-between sm:justify-end">
                        <button
                          onClick={handlePreviousClick}
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Previous
                        </button>
                        <button
                          onClick={handleNextClick}
                          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Next
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminUserView userId={selectedUserId} isOpen={isModalOpen} onClose={handleCloseModal} refetchUsers={fetchUsers} />
    </div>
  );
}

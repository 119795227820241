import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { RequireAuth, RequireNoAuth, RequireAdmin, RequireReferralPartner, RequireSuninSalesPartner } from "./services/authorization-services/require-auth.service";

// Page imports
import Login from "./pages/Login";
import Signup from "./pages/website/Signup";
import Landing from "./pages/Home";
import Accounts from "./pages/accounts/Accounts";
import Account from "./pages/accounts/Account";
import Project from "./pages/projects/Project";
import Profile from "./pages/Profile";
import Pricing from "./pages/website/Pricing";
import Contact from "./pages/website/Contact";
import Projects from './pages/projects/Projects';
import IndexPage from "./pages/website/Index";
import CheckoutPage from "./pages/website/Checkout";
import VerifyEmail from "./components/VerifyEmail.component";
import Leads from "./pages/leads/Leads";
import SuninSchool from "./pages/education/SuninSchool";
import UpgradeSubscription from "./pages/UpgradeSubscription";
import WebLayout from './layout/WebLayout';
import AppLayout from './layout/AppLayout';
import AdminLayout from './layout/AdminLayout'; // Import the AdminLayout
import ActivatingNowPage from "./components/website/ActivatingNowPage";
import PasswordResetRequest from "./pages/PasswordResetRequest";
import PasswordResetForm from "./pages/PasswordResetForm";
import Team from "./pages/website/Team";
import Mission from "./pages/website/Mission";
import AllInOnePlatform from "./components/website/AllInOnePlatfrom.component";
import Solar from "./pages/Solar";
import ViewLeadComponent from "./components/leads/ViewLead.component";
import AdminPortal from "./pages/admin/AdminPortal";
import AdminProjectView from "./pages/admin/AdminProjectView";
import AdminAccountView from "./pages/admin/AdminAccountView";
import ViewContactModal from "./components/projects/ViewContact.component";
import Notifications from "./pages/Notifications";

import AllUsers from './pages/admin/AllUsers';
import AllAccounts from './pages/admin/AllAccounts';
import AllProjects from './pages/admin/AllProjects';
import UserDetails from './pages/admin/UserDetails';

import CompanyDashboard from './pages/admin/CompanyDashboard';
// Authorization components

import AdminProposalManagement from './pages/admin/AdminProposalManagement';
import AdministratorManagement from './pages/admin/AdministrationManagement';
import AdminAllReferrals from './pages/admin/AllReferrals';

function App() {
  return (
    <>
      {/* Notification Toaster */}
      <Toaster
        position="top-center"
        containerStyle={{ marginTop: '50px' }}
        reverseOrder={false}
        toastOptions={{
          duration: 5000,
          style: { background: '#333', color: '#fff' },
          success: { duration: 3000, theme: { primary: 'green', secondary: 'black' } },
        }}
      />

      {/* Browser Router */}
      <BrowserRouter>
        {/* Vercel Analytics and Speed Insights */}
        <Analytics />
        <SpeedInsights />

        {/* Routes Configuration */}
        <Routes>
          {/* WebLayout for public-facing pages */}
          <Route element={<WebLayout />}>
            <Route path="/" element={<IndexPage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/verify_email" element={<VerifyEmail />} />
            <Route path="/activating-now" element={<RequireAuth><ActivatingNowPage /></RequireAuth>} />
            <Route path="/request-password-reset" element={<PasswordResetRequest />} />
            <Route path="/password-reset-form/:reset_password_token" element={<PasswordResetForm />} />
            <Route path="/team" element={<Team />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/services" element={<AllInOnePlatform />} />
            <Route path="/solar" element={<Solar />} />
          </Route>

          {/* AppLayout for application/internal pages */}
          <Route element={<AppLayout />}>
            <Route path="/home" element={<RequireAuth><Landing /></RequireAuth>} />
            <Route path="/referrals" element={<RequireReferralPartner><Leads /></RequireReferralPartner>} />
            <Route path="/referrals/:id" element={<RequireReferralPartner><ViewLeadComponent /></RequireReferralPartner>} />
            <Route path="/accounts" element={<RequireSuninSalesPartner><Accounts /></RequireSuninSalesPartner>} />
            <Route path="/accounts/:id" element={<RequireSuninSalesPartner><Account /></RequireSuninSalesPartner>} />
            <Route path="/projects" element={<RequireSuninSalesPartner><Projects /></RequireSuninSalesPartner>} />
            <Route path="/projects/:id" element={<RequireSuninSalesPartner><Project /></RequireSuninSalesPartner>} />
            <Route path="/projects/:id/contacts/:id" element={<RequireSuninSalesPartner><ViewContactModal /></RequireSuninSalesPartner>} />
            <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />
            <Route path="/sunin-school" element={<RequireAuth><SuninSchool /></RequireAuth>} />
            <Route path="/notifications" element={<Notifications/>}/>
          </Route>

          {/* AdminLayout for admin-specific pages */}
          <Route element={<AdminLayout />}>
            <Route path="/admin" element={<RequireAdmin><CompanyDashboard/></RequireAdmin>} />
            <Route path="/admin/administrator-management" element={<RequireAdmin><AdministratorManagement/></RequireAdmin>} />
            <Route path="/admin/proposal-management" element={<RequireAdmin><AdminProposalManagement></AdminProposalManagement></RequireAdmin>} />
            <Route path="admin/all-users" element={<RequireAdmin><AllUsers/></RequireAdmin>} />
            <Route path="admin/all-accounts" element={<RequireAdmin><AllAccounts/></RequireAdmin>} />
            <Route path="admin/all-projects" element={<RequireAdmin><AllProjects/></RequireAdmin>} />
            <Route path="admin/all-referrals" element={<RequireAdmin><AdminAllReferrals/></RequireAdmin>} />
            <Route path="/admin/projects/:id" element={<RequireAdmin><AdminProjectView/></RequireAdmin>} />
            <Route path="/admin/accounts/:id" element={<RequireAdmin><AdminAccountView/></RequireAdmin>} />
            <Route path="/admin/all-users/:id" element={<RequireAdmin><UserDetails /></RequireAdmin>} />
          </Route>

          {/* Standalone or Utility Routes */}
          <Route path="/upgradesubscription" element={<UpgradeSubscription />} />
          <Route path="/sitemap.xml" />

          {/* Add other standalone routes as needed */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import http from "../../http-common";
import 'react-toastify/dist/ReactToastify.css';

/**
 * Example component to create and submit a new project via a modal form.
 * 
 * @param {Object} props - The component props
 * @param {boolean} props.isModalOpen - Flag to determine if the modal is open
 * @param {function} props.setIsModalOpen - Function to set the modal open state
 * @returns {JSX.Element} The Example component
 */
export default function Example({ isModalOpen, setIsModalOpen }) {
  const cancelButtonRef = useRef(null);
  const account_id = extractAccountIdFromUrl(window.location.href);
  const project_stage = 'Project Initiation';
  const [currentStep, setCurrentStep] = useState(0);
  const [company_name, setCompanyName] = useState('');
  const [address_1, setAddressOne] = useState('');
  const [address_2, setAddressTwo] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [additional_company_notes, setCompanyNotes] = useState('');

  const [utility_company, setUtilityCompany] = useState('');
  const [utility_account_holder, setUtilityAccountHolder] = useState('');
  const [utility_account_number, setUtilityAccountNumber] = useState('');
  const [meter_number, setMeterNumber] = useState('');
  const [number_of_meters, setNumMeters] = useState('');
  const [main_panel_amperage, setMainPanelAmperage] = useState('');
  const [power_bill_received, setBillReceivedValue] = useState('');
  const [january_kilowatt_amount, setJanKw] = useState('');
  const [february_kilowatt_amount, setFebKw] = useState('');
  const [march_kilowatt_amount, setMarKw] = useState('');
  const [april_kilowatt_amount, setAprKw] = useState('');
  const [may_kilowatt_amount, setMayKw] = useState('');
  const [june_kilowatt_amount, setJunKw] = useState('');
  const [july_kilowatt_amount, setJulKw] = useState('');
  const [august_kilowatt_amount, setAugKw] = useState('');
  const [september_kilowatt_amount, setSepKw] = useState('');
  const [october_kilowatt_amount, setOctKw] = useState('');
  const [november_kilowatt_amount, setNovKw] = useState('');
  const [december_kilowatt_amount, setDecKw] = useState('');
  const [average_monthly_power_bill_cost, setMonthlyPowerBill] = useState('');
  const [additional_utility_notes, setUtilityNotes] = useState('');

  const [shade_interference, setShadeInterference] = useState('');
  const [shade_interference_details, setShadeDescription] = useState('');
  const [type_of_roof, setTypeOfRoof] = useState('');
  const [age_of_roof, setAgeOfRoof] = useState('');
  const [roof_description, setRoofDescription] = useState('');

  const modalContentRef = useRef(null);

  const scrollToTopWithOffset = (offset = 50) => {
    if (modalContentRef.current) {
      const topPosition = modalContentRef.current.offsetTop - offset;
      modalContentRef.current.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        modalContentRef.current.parentElement.scrollTop = topPosition;
      }, 300); // Adjust the delay as needed to match the smooth scrolling animation
    }
  };

  /**
   * Extracts the account ID from the given URL.
   * 
   * @param {string} url - The URL to extract the account ID from
   * @returns {string} The extracted account ID
   */
  function extractAccountIdFromUrl(url) {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  /**
   * Resets the form to its initial state.
   */
  const resetForm = () => {
    setCompanyName('');
    setAddressOne('');
    setAddressTwo('');
    setCity('');
    setState('');
    setZip('');
    setCompanyNotes('');

    setUtilityCompany('');
    setUtilityAccountHolder('');
    setUtilityAccountNumber('');
    setMeterNumber('');
    setNumMeters('');
    setMainPanelAmperage('');
    setBillReceivedValue('');
    setJanKw('');
    setFebKw('');
    setMarKw('');
    setAprKw('');
    setMayKw('');
    setJunKw('');
    setJulKw('');
    setAugKw('');
    setSepKw('');
    setOctKw('');
    setNovKw('');
    setDecKw('');
    setMonthlyPowerBill('');
    setUtilityNotes('');

    setShadeInterference('');
    setShadeDescription('');
    setTypeOfRoof('');
    setAgeOfRoof('');
    setRoofDescription('');

    setCurrentStep(0);
  };

  /**
   * Submits the new project information to the server.
   * 
   * @param {Object} data - The project data to be submitted
   */
  const submitNewProjectInfo = async (data) => {
    try {
      const response = await http.post("/projects", { "project": data });
      const projectId = response.data.projectId;
      setIsModalOpen(false);
      resetForm();
      window.location.href = `/projects/${projectId}`;
    } catch (error) {
      alert("Failed with error: ", error);
    }
  };

  /**
   * Handles the creation of a new project by gathering the form data and submitting it.
   */
  const handleNewProject = () => {
    const data = {
      account_id,
      project_stage,
      company_name,
      address_1,
      address_2,
      city,
      state,
      zip,
      additional_company_notes,
      utility_company,
      utility_account_holder,
      utility_account_number,
      meter_number,
      number_of_meters,
      main_panel_amperage,
      power_bill_received,
      january_kilowatt_amount,
      february_kilowatt_amount,
      march_kilowatt_amount,
      april_kilowatt_amount,
      may_kilowatt_amount,
      june_kilowatt_amount,
      july_kilowatt_amount,
      august_kilowatt_amount,
      september_kilowatt_amount,
      october_kilowatt_amount,
      november_kilowatt_amount,
      december_kilowatt_amount,
      average_monthly_power_bill_cost,
      additional_utility_notes,
      shade_interference,
      shade_interference_details,
      type_of_roof,
      age_of_roof,
      roof_description,
    };
    submitNewProjectInfo(data);
  };

  /**
   * Handles the cancel action, resetting the form and closing the modal.
   */
  const handleCancel = () => {
    resetForm();
    setIsModalOpen(false);
  };

  /**
   * Renders an input field for monthly kilowatt usage.
   * 
   * @param {string} month - The month for the kilowatt input
   * @param {string} value - The current value of the input field
   * @param {function} setValue - The state setter function for the input field
   * @returns {JSX.Element} The rendered kilowatt input field
   */
  const renderKilowattInput = (month, value, setValue) => (
    <div className="col-span-6 sm:col-span-3">
      <label htmlFor={`${month.toLowerCase()}_kilowatt_amount`} className="block text-sm font-medium leading-6 text-gray-900">
        {month}
      </label>
      <div className="mt-2">
        <input
          type="number"
          name={`${month.toLowerCase()}_kilowatt_amount`}
          id={`${month.toLowerCase()}_kilowatt_amount`}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
        />
      </div>
    </div>
  );

  /**
   * Renders the company information section of the form.
   * 
   * @returns {JSX.Element} The rendered company information section
   */
  const renderCompanyInfo = () => (
    <>
      <div className='mt-5'></div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
        <div className='mt-5'>
          <h2 className="text-base pt-4 border-t border-gray-900/10 font-semibold leading-7 text-gray-900">
            Company Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Enter the core company details that will be associated with this project. This includes the main address where project-related communications will be sent.
          </p>
        </div>

        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 md:mt-5">
          <div className="col-span-6">
            <label htmlFor="company_name" className="block text-sm font-medium leading-6 text-gray-900">
              Company
            </label>
            <div className="mt-2">
              <input
                id="company_name"
                name="company_name"
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={company_name}
                onChange={(evt) => setCompanyName(evt.target.value)}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="address_1" className="block text-sm font-medium leading-6 text-gray-900">
              Street address Line 1
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="address_1"
                id="address_1"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={address_1}
                onChange={(evt) => setAddressOne(evt.target.value)}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="address_2" className="block text-sm font-medium leading-6 text-gray-900">
              Street address Line 2
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="address_2"
                id="address_2"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={address_2}
                onChange={(evt) => setAddressTwo(evt.target.value)}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
              City
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="city"
                id="city"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={city}
                onChange={(evt) => setCity(evt.target.value)}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">
              State
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="state"
                name="state"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={state}
                onChange={(evt) => setState(evt.target.value)}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="zip" className="block text-sm font-medium leading-6 text-gray-900">
              Postal code
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="zip"
                id="zip"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={zip}
                onChange={(evt) => setZip(evt.target.value)}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="additional_company_notes" className="block text-sm font-medium leading-6 text-gray-900">
              Additional company notes
            </label>
            <div className="mt-2">
              <textarea
                id="additional_company_notes"
                name="additional_company_notes"
                rows={3}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={additional_company_notes}
                onChange={(evt) => setCompanyNotes(evt.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  /**
   * Renders the utility information section of the form.
   * 
   * @returns {JSX.Element} The rendered utility information section
   */
  const renderUtilityInfo = () => (
    <>
      <div className='mt-5'></div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
        <div>
          <h2 className=" text-base font-semibold leading-7 text-gray-900">Utility Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Provide utility information related to the project site. This includes details about the utility accounts, meter numbers, and average power usage which are essential for project energy assessments.
          </p>
        </div>

        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
          <div className="col-span-6">
            <label htmlFor="utility_company" className="block text-sm font-medium leading-6 text-gray-900">
              Utility Company
            </label>
            <div className="mt-2">
              <input
                id="utility_company"
                name="utility_company"
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={utility_company}
                onChange={(evt) => setUtilityCompany(evt.target.value)}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="utility_account_holder" className="block text-sm font-medium leading-6 text-gray-900">
              Account holder
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="utility_account_holder"
                id="utility_account_holder"
                autoComplete="given-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={utility_account_holder}
                onChange={(evt) => setUtilityAccountHolder(evt.target.value)}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="utility_account_number" className="block text-sm font-medium leading-6 text-gray-900">
              Account number
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="utility_account_number"
                id="utility_account_number"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={utility_account_number}
                onChange={(evt) => setUtilityAccountNumber(evt.target.value)}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="meter_number" className="block text-sm font-medium leading-6 text-gray-900">
              Meter number
            </label>
            <div className="mt-2">
              <input
                id="meter_number"
                name="meter_number"
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={meter_number}
                onChange={(evt) => setMeterNumber(evt.target.value)}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="number_of_meters" className="block text-sm font-medium leading-6 text-gray-900">
              Number of meters on property
            </label>
            <div className="mt-2">
              <select
                id="number_of_meters"
                name="number_of_meters"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={number_of_meters}
                onChange={(evt) => setNumMeters(evt.target.value)}
              >
                <option value="">Select...</option>
                <option value="1 Meter">1 Meter</option>
                <option value="2 Meters">2 Meters</option>
                <option value="3 Meters">3 Meters</option>
                <option value="4 Meters">4 Meters</option>
                <option value="5+ Meters">5+ Meters</option>
              </select>
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="main_panel_amperage" className="block text-sm font-medium leading-6 text-gray-900">
              Main panel amperage
            </label>
            <div className="mt-2">
              <select
                id="main_panel_amperage"
                name="main_panel_amperage"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={main_panel_amperage}
                onChange={(evt) => setMainPanelAmperage(evt.target.value)}
              >
                <option value="">Select...</option>
                <option value="100 Amps">100 Amps</option>
                <option value="200 Amps">200 Amps</option>
                <option value="400 Amps">400 Amps</option>
                <option value="800 Amps">800 Amps</option>
                <option value="1000+ Amps">1000+ Amps</option>
              </select>
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="power_bill_received" className="block text-sm font-medium leading-6 text-gray-900">
              Power bill Received
            </label>
            <div className="mt-2">
              <select
                id="power_bill_received"
                name="power_bill_received"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={power_bill_received}
                onChange={(evt) => setBillReceivedValue(evt.target.value)}
              >
                <option value="">Select...</option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          </div>

          <div className='col-span-6'>
            <h3 className="text-base font-semibold leading-7 text-gray-900 pt-4 border-t border-gray-900/10">Kilowatts</h3>
            <p className="mt-1 text-sm leading-6 text-gray-600 ">
              Input monthly kilowatt usage to help in the assessment of energy needs and system sizing. Accurate figures are crucial for designing an efficient energy system.
            </p>
          </div>

          {renderKilowattInput("January", january_kilowatt_amount, setJanKw)}
          {renderKilowattInput("February", february_kilowatt_amount, setFebKw)}
          {renderKilowattInput("March", march_kilowatt_amount, setMarKw)}
          {renderKilowattInput("April", april_kilowatt_amount, setAprKw)}
          {renderKilowattInput("May", may_kilowatt_amount, setMayKw)}
          {renderKilowattInput("June", june_kilowatt_amount, setJunKw)}
          {renderKilowattInput("July", july_kilowatt_amount, setJulKw)}
          {renderKilowattInput("August", august_kilowatt_amount, setAugKw)}
          {renderKilowattInput("September", september_kilowatt_amount, setSepKw)}
          {renderKilowattInput("October", october_kilowatt_amount, setOctKw)}
          {renderKilowattInput("November", november_kilowatt_amount, setNovKw)}
          {renderKilowattInput("December", december_kilowatt_amount, setDecKw)}

          <div className="col-span-6">
            <label htmlFor="average_monthly_power_bill_cost" className="block text-sm font-medium leading-6 text-gray-900">
              Average monthly power bill
            </label>
            <div className="col-span-6 flex">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                $
              </span>
              <input
                type="text"
                name="average_monthly_power_bill_cost"
                id="average_monthly_power_bill_cost"
                className="flex-1 block w-full rounded-none rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={average_monthly_power_bill_cost.replace(/[^0-9.]/g, '')}
                onChange={(evt) => {
                  const value = evt.target.value;
                  setMonthlyPowerBill(value.replace(/[^0-9.]/g, ''));
                }}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="additional_utility_notes" className="block text-sm font-medium leading-6 text-gray-900">
              Additional utility notes
            </label>
            <div className="mt-2">
              <textarea
                id="additional_utility_notes"
                name="additional_utility_notes"
                rows={3}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={additional_utility_notes}
                onChange={(evt) => setUtilityNotes(evt.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  /**
   * Renders the property information section of the form.
   * 
   * @returns {JSX.Element} The rendered property information section
   */
  const renderPropertyInfo = () => (
    <>
      <div className='mt-5'></div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Property Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Detail the physical aspects of the property where the project will be implemented. Information about roof type, age, and any potential shading will affect the installation and performance of the system.
          </p>
        </div>

        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
          <div className="col-span-6">
            <label htmlFor="shade_interference" className="block text-sm font-medium leading-6 text-gray-900">
              Is there any shade interference?
            </label>
            <div className="mt-2">
              <select
                id="shade_interference"
                name="shade_interference"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={shade_interference}
                onChange={(evt) => setShadeInterference(evt.target.value)}
              >
                <option value="">Select...</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="shade_interference_details" className="block text-sm font-medium leading-6 text-gray-900">
              Shade Description
            </label>
            <div className="mt-2">
              <textarea
                id="shade_interference_details"
                name="shade_interference_details"
                rows={3}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={shade_interference_details}
                onChange={(evt) => setShadeDescription(evt.target.value)}
              />
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="type_of_roof" className="block text-sm font-medium leading-6 text-gray-900">
              Type of roof
            </label>
            <div className="mt-2">
              <select
                id="type_of_roof"
                name="type_of_roof"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={type_of_roof}
                onChange={(evt) => setTypeOfRoof(evt.target.value)}
              >
                <option value="">Select...</option>
                <option value="Concrete Tile">Concrete Tile</option>
                <option value="Asphalt Shingle">Asphalt Shingle</option>
                <option value="Metal Roofing">Metal Roofing</option>
                <option value="Flat Roof">Flat Roof</option>
                <option value="Rubber (EPDM)">Rubber (EPDM)</option>
                <option value="TPO and PVC Roofing">TPO and PVC Roofing</option>
                <option value="Slate Tile">Slate Tile</option>
                <option value="Clay Tile">Clay Tile</option>
                <option value="Wood Shingles/Shakes">Wood Shingles/Shakes</option>
                <option value="Green Roof">Green Roof</option>
                <option value="Built-Up Roofing (BUR)">Built-Up Roofing (BUR)</option>
                <option value="Composite Roofing">Composite Roofing</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="age_of_roof" className="block text-sm font-medium leading-6 text-gray-900">
              Age of roof
            </label>
            <div className="mt-2">
              <select
                id="age_of_roof"
                name="age_of_roof"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={age_of_roof}
                onChange={(evt) => setAgeOfRoof(evt.target.value)}
              >
                <option value="">Select...</option>
                <option value="0-5 Years">0-5 Years</option>
                <option value="6-10 Years">6-10 Years</option>
                <option value="11-15 Years">11-15 Years</option>
                <option value="16-20 Years">16-20 Years</option>
                <option value="21+ Years">21+ Years</option>
              </select>
            </div>
          </div>

          <div className="col-span-6">
            <label htmlFor="roof_description" className="block text-sm font-medium leading-6 text-gray-900">
              Roof description
            </label>
            <div className="mt-2">
              <textarea
                id="roof_description"
                name="roof_description"
                rows={3}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={roof_description}
                onChange={(evt) => setRoofDescription(evt.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  /**
   * Renders the navigation buttons for the form steps.
   * 
   * @returns {JSX.Element} The rendered navigation buttons
   */
  const renderButtons = () => (
    <div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
      {currentStep > 0 && (
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1"
          onClick={() => {
            scrollToTopWithOffset(50); // Adjust the offset value as needed
            setCurrentStep(currentStep - 1);
          }}
        >
          Previous
        </button>
      )}
      {currentStep < 2 ? (
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 mt-2"
          onClick={() => {
            scrollToTopWithOffset(50); // Adjust the offset value as needed
            setCurrentStep(currentStep + 1);
          }}
        >
          Next
        </button>
      ) : (
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 mt-2"
          onClick={handleNewProject}
        >
          Submit
        </button>
      )}
      <button
        type="button"
        className="mt-12 inline-flex w-full justify-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 sm:col-span-2"
        onClick={handleCancel}
        ref={cancelButtonRef}
      >
        Cancel
      </button>
    </div>
  );
  

  /**
   * Renders the main modal component.
   * 
   * @returns {JSX.Element} The rendered modal component
   */
  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" initialFocus={cancelButtonRef} onClose={setIsModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
  
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-3xl sm:p-6">
                <div ref={modalContentRef}>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      New Project
                    </Dialog.Title>
                  </div>
                  <div className="border-b border-gray-900/10 pb-4">
                    <form>
                      {currentStep === 0 && renderCompanyInfo()}
                      {currentStep === 1 && renderUtilityInfo()}
                      {currentStep === 2 && renderPropertyInfo()}
                      {renderButtons()}
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}  
import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import leadService from '../../services/leads.service'; // Adjust the import path as needed
import toast from 'react-hot-toast';
import { PlusIcon } from '@heroicons/react/20/solid';


export default function NewLead({ isModalOpen, setIsModalOpen, fetchLeads }) {
  const cancelButtonRef = useRef(null);
  const [first_name, setFirstName] = useState('');
  const [validFirstName, setValidFirstName] = useState(false);
  const [last_name, setLastName] = useState('');
  const [validLastName, setValidLastName] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [phone_number, setPhoneNumber] = useState('');
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [company_name, setCompanyName] = useState('');
  const [validCompanyName, setValidCompanyName] = useState(false);
  const [address_1, setAddressOne] = useState('');
  const [validAddressOne, setValidAddressOne] = useState(false);
  const [address_2, setAddressTwo] = useState('');
  const [city, setCity] = useState('');
  const [validCity, setValidCity] = useState(false);
  const [state, setState] = useState('');
  const [validState, setValidState] = useState(false);
  const [zip, setZip] = useState('');
  const [validZip, setValidZip] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const handleAddPhotos = (event) => {
    const newPhotos = Array.from(event.target.files).map(file => ({
      file,
      url: URL.createObjectURL(file) 
    }));
    setPhotos(photos.concat(newPhotos)); 
  };


  const submitNewLeadInfo = async () => {
    setIsLoading(true);
    const data = {
      first_name,
      last_name,
      email,
      phone_number,
      company_name,
      address_1,
      address_2,
      city,
      state,
      zip,
    };

    try {
      const response = await leadService.newLead(data);
      if (response.status === 200) { 
        const leadId = response.data.leadId;
        const formData = new FormData();
        photos.forEach(photo => formData.append('files[]', photo.file)); 
        try {
          const uploadResponse = await leadService.uploadLeadFiles(leadId, formData);
          if (uploadResponse.status === 201) {
            toast.success('Referral submitted successfully!');
            setCurrentStep(2); 
            fetchLeads();
          } else {
            toast.error('Failed to upload files.');
          }
        }
        catch (error) {
          console.error("Failed to upload files:", error);
          toast.error('Failed to upload files.');
        }
      } else {
        toast.error('Failed to create lead.');
      }
    } catch (error) {
      console.error("Error creating lead:", error);
      toast.error('Failed to create lead.');
    } finally {
      setIsModalOpen(false); 
      resetAllStates();
      setIsLoading(false);
    }
  };


  const removePhoto = (index) => {
    const filteredPhotos = photos.filter((_, idx) => idx !== index);
    URL.revokeObjectURL(photos[index].url); 
    setPhotos(filteredPhotos);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
    window.scrollTo(0, 0);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  const checkFirstName = (value) => setValidFirstName(value.trim().length > 0);
  const checkLastName = (value) => setValidLastName(value.trim().length > 0);
  const checkEmail = (value) => setValidEmail(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
  const checkPhoneNumber = (value) => setValidPhoneNumber(/^\d{10}$/.test(value)); // Example: U.S. standard without country code
  const checkCompanyName = (value) => setValidCompanyName(value.trim().length > 0);
  const checkAddressOne = (value) => setValidAddressOne(value.trim().length > 0);
  const checkCity = (value) => setValidCity(value.trim().length > 0);
  const checkState = (value) => setValidState(value.trim().length > 0);
  const checkZip = (value) => setValidZip(/^\d{5}(-\d{4})?$/.test(value)); // U.S. ZIP and ZIP+4

  const handleFirstNameChange = (value) => {
    setFirstName(value);
    checkFirstName(value);
  };

  const handleLastNameChange = (value) => {
    setLastName(value);
    checkLastName(value);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    checkEmail(value);
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    checkPhoneNumber(value);
  };

  const handleCompanyNameChange = (value) => {
    setCompanyName(value);
    checkCompanyName(value);
  };

  const handleAddressOneChange = (value) => {
    setAddressOne(value);
    checkAddressOne(value);
  };

  const handleCityChange = (value) => {
    setCity(value);
    checkCity(value);
  };

  const handleStateChange = (value) => {
    setState(value);
    checkState(value);
  };

  const handleZipChange = (value) => {
    setZip(value);
    checkZip(value);
  };

  const resetAllStates = () => {
    setIsModalOpen(false);
    setFirstName('');
    setValidFirstName(false);
    setLastName('');
    setValidLastName(false);
    setEmail('');
    setValidEmail(false);
    setPhoneNumber('');
    setValidPhoneNumber(false);
    setCompanyName('');
    setValidCompanyName(false);
    setAddressOne('');
    setValidAddressOne(false);
    setAddressTwo('');
    setCity('');
    setValidCity(false);
    setState('');
    setValidState(false);
    setZip('');
    setValidZip(false);
    setCurrentStep(1);
    setPhotos([]);
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" initialFocus={cancelButtonRef} onClose={setIsModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-xl sm:p-6">
                {isLoading && (
                  <div className="absolute inset-0 bg-gray-500 bg-opacity-50 z-[1000] flex justify-center items-center">
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                <div className="border-b border-gray-900/10 pb-2">
                  {currentStep === 1 ? (
                    <>
                      <div className="mt-3 text-center sm:mt-5">
                        <h2 className="mt-6 text-2xl font-extrabold text-gray-900">New Referral</h2>
                        <p className="mt-2 text-sm text-gray-600">Complete all of the necessary requirements to submit your referral!</p>
                      </div>

                      <div className="mt-10 mx-auto max-w-xl">
                        <label htmlFor="company_name" className="block text-sm font-medium leading-6 text-gray-900">
                          Company name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="company_name"
                            id="company_name"
                            className={`block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${validCompanyName ? 'border-gray-300' : 'border-red-500'}`}
                            value={company_name}
                            onChange={(e) => handleCompanyNameChange(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-4 mx-auto max-w-xl">
                        <label htmlFor="address_1" className="block text-sm font-medium leading-6 text-gray-900">
                          Street address line 1
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="address_1"
                            id="address_1"
                            className={`block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${validAddressOne ? 'border-gray-300' : 'border-red-500'}`}
                            value={address_1}
                            onChange={(e) => handleAddressOneChange(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-4 mx-auto max-w-xl">
                        <label htmlFor="address_2" className="block text-sm font-medium leading-6 text-gray-900">
                          Street address line 2
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="address_2"
                            id="address_2"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={address_2}
                            onChange={(e) => setAddressTwo(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-4 mx-auto max-w-xl">
                        <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                          City
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className={`block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${validCity ? 'border-gray-300' : 'border-red-500'}`}
                            value={city}
                            onChange={(e) => handleCityChange(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-4 mx-auto max-w-xl">
                        <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">
                          State
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="state"
                            id="state"
                            className={`block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${validState ? 'border-gray-300' : 'border-red-500'}`}
                            value={state}
                            onChange={(e) => handleStateChange(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-4 mx-auto max-w-xl">
                        <label htmlFor="zip" className="block text-sm font-medium leading-6 text-gray-900">
                          Postal Code
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="zip"
                            id="zip"
                            className={`block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${validZip ? 'border-gray-300' : 'border-red-500'}`}
                            value={zip}
                            onChange={(e) => handleZipChange(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-4 mx-auto max-w-xl">
                        <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
                          Contact first name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            className={`block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${validFirstName ? 'border-gray-300' : 'border-red-500'}`}
                            value={first_name}
                            onChange={(e) => handleFirstNameChange(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-4 mx-auto max-w-xl">
                        <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                          Contact last name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            className={`block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${validLastName ? 'border-gray-300' : 'border-red-500'}`}
                            value={last_name}
                            onChange={(e) => handleLastNameChange(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-4 mx-auto max-w-xl">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                          Contact email
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className={`block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${validEmail ? 'border-gray-300' : 'border-red-500'}`}
                            value={email}
                            onChange={(e) => handleEmailChange(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-4 mx-auto max-w-xl">
                        <label htmlFor="phone_number" className="block text-sm font-medium leading-6 text-gray-900">
                          Contact phone number
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            className={`block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${validPhoneNumber ? 'border-gray-300' : 'border-red-500'}`}
                            value={phone_number}
                            onChange={(e) => handlePhoneNumberChange(e.target.value)}
                          />
                        </div>
                      </div>

                      {validCompanyName && validAddressOne && validCity
                        && validState && validZip && validFirstName &&
                        validLastName && validEmail && validPhoneNumber &&
                        <div className="flex justify-center mt-8">
                          <button
                            type="submit"
                            onClick={nextStep}
                            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2 mt-2"
    >
                            Next
                          </button>
                        </div>
                      }
                    </>
                  ) : (
                    <>
                      <div className="px-4 py-5 sm:p-6">
                        <div className="text-center mb-12">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              vectorEffect="non-scaling-stroke"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                            />
                          </svg>
                          <h3 className="mt-2 text-sm font-semibold text-gray-900">No Power Bill Uploaded</h3>
                          <p className="mt-1 text-sm text-gray-500">Finish up by adding a pdf or photo of your referrals power bill. Feel free to upload multiple if necessary</p>
                          
                          <div className="mt-6">
                            <label className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none">
                              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                              {photos.length <= 0 ? (
                                'Add the Power Bill'
                              ) : (
                                'Add more files'
                              )}
                              <input type="file" accept="*" multiple onChange={handleAddPhotos} className="hidden" />
                            </label>
                          </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                          {photos.map((photo, index) => (
                            <div key={index} className="bg-white shadow-xl rounded-lg overflow-hidden border border-gray-200">
                              <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-t-lg bg-gray-100">
                                <img className="w-full h-full object-cover" src={photo.url} alt={`${index + 1}`} />
                              </div>
                              <div className="p-4 flex justify-center items-center">
                                <button onClick={() => removePhoto(index)} className="flex items-center text-red-500 hover:text-red-600 transition-colors duration-150 ease-in-out">
                                  <svg className="w-4 h-4 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M3 6h18"></path>
                                    <path d="M19 6v14c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V6m3 0V4c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2v2"></path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                  </svg>
                                  <span className="inline">Remove</span>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {photos.length > 0 && (
                        <div className="flex justify-center mt-8">
                          <button
                            type="submit"
                            onClick={submitNewLeadInfo}
                            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2 mt-2"
                            >
                            Submit Referral
                          </button>
                        </div>
                      )}
                      <div className="flex justify-center mt-2">
                        <button
                          type="submit"
                          onClick={prevStep}
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1"
                          >
                          Previous
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex justify-center mt-2">
                  <button
                    type="submit"
                    onClick={resetAllStates}
                    className="mt-12 inline-flex w-full justify-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-yellow-300 sm:col-span-2"

                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import http from "../http-common";
import serviceUtil from "../utils/service-util";

class AdminService {
  async getUsers() {
    return await http.get("/admin/users");
  }

  async getUser(id) {
    return await http.get(`/admin/users/${id}`);
  }

  async createUser(data) {
    return await http.post("/admin/users", { user: data });
  }

  async updateUser(id, data) {
    return await http.put(`/admin/users/${id}`, { user: data });
  }

  async deleteUser(id) {
    return await http.delete(`/admin/users/${id}`);
  }

  async getAllLeads() {
    return await http.get("/admin/leads");
  }

  async getLeads(userId) {
    return await http.get(`/admin/users/${userId}/leads`);
  }

  async getLead(userId, leadId) {
    return await http.get(`/admin/users/${userId}/leads/${leadId}`);
  }

  async createLead(userId, data) {
    return await http.post(`/admin/users/${userId}/leads`, { lead: data });
  }

  async updateLead(userId, leadId, data) {
    return await http.put(`/admin/users/${userId}/leads/${leadId}`, {
      lead: data,
    });
  }

  async deleteLead(userId, leadId) {
    return await http.delete(`/admin/users/${userId}/leads/${leadId}`);
  }

  async getAllAccounts() {
    return await http.get("/admin/accounts");
  }

  async getAccounts(userId) {
    return await http.get(`/admin/users/${userId}/accounts`);
  }

  async getAccount(accountId) {
    if (!accountId) {
      const url = window.location.href;
      const parts = url.split("/");
      const accountId = parts[parts.length - 1];
      return await http.get(`/admin/accounts/${accountId}`);
    } else {
      return await http.get(`/admin/accounts/${accountId}`);
    }
  }

  async createAccount(data) {
    return await http.post("/admin/accounts", { account: data });
  }

  async updateAccount(accountId, data) {
    return await http.put(`/admin/accounts/${accountId}`, { account: data });
  }

  async deleteAccount(accountId) {
    return await http.delete(`/admin/accounts/${accountId}`);
  }

  async getAllProjects() {
    return await http.get("/admin/projects");
  }

  async getProjects(userId) {
    return await http.get(`/admin/users/${userId}/projects`);
  }

  async getProject() {
    const id = serviceUtil.extrapolateFinalIdFromUrl();
    return await http.get(`/admin/projects/${id}`);
  }

  async createProject(data) {
    return await http.post("/admin/projects", { project: data });
  }

  async updateProject(projectId, data) {
    return await http.put(`/admin/projects/${projectId}`, { project: data });
  }

  async deleteProject(projectId) {
    return await http.delete(`/admin/projects/${projectId}`);
  }

  async getProjectContacts(projectId) {
    return await http.get(`/admin/projects/${projectId}/contacts`);
  }

  async getProjectContact(projectId, contactId) {
    return await http.get(`/admin/projects/${projectId}/contacts/${contactId}`);
  }

  async createProjectContact(projectId, data) {
    return await http.post(`/admin/projects/${projectId}/contacts`, {
      contact: data,
    });
  }

  async updateProjectContact(projectId, contactId, data) {
    return await http.put(
      `/admin/projects/${projectId}/contacts/${contactId}`,
      { contact: data }
    );
  }

  async deleteProjectContact(projectId, contactId) {
    return await http.delete(
      `/admin/projects/${projectId}/contacts/${contactId}`
    );
  }

  async getProjectLinks(projectId) {
    return await http.get(`/admin/projects/${projectId}/project_links`);
  }

  async getProjectLink(projectId, linkId) {
    return await http.get(
      `/admin/projects/${projectId}/project_links/${linkId}`
    );
  }

  async createProjectLink(projectId, data) {
    return await http.post(`/admin/projects/${projectId}/project_links`, {
      project_link: data,
    });
  }

  async updateProjectLink(projectId, linkId, data) {
    return await http.put(
      `/admin/projects/${projectId}/project_links/${linkId}`,
      { project_link: data }
    );
  }

  async deleteProjectLink(projectId, linkId) {
    return await http.delete(
      `/admin/projects/${projectId}/project_links/${linkId}`
    );
  }

  async getProjectImages(projectId) {
    return await http.get(`/admin/projects/${projectId}/images`);
  }

  async getProjectImage(projectId, imageId) {
    return await http.get(`/admin/projects/${projectId}/images/${imageId}`);
  }

  async createProjectImage(projectId, data) {
    return await http.post(`/admin/projects/${projectId}/images`, {
      image: data,
    });
  }

  async updateProjectImage(projectId, imageId, data) {
    return await http.put(`/admin/projects/${projectId}/images/${imageId}`, {
      image: data,
    });
  }

  async deleteProjectImage(projectId, imageId) {
    return await http.delete(`/admin/projects/${projectId}/images/${imageId}`);
  }

  async getProjectFiles(projectId) {
    return await http.get(`/admin/projects/${projectId}/files`);
  }

  async getProjectFile(projectId, fileId) {
    return await http.get(`/admin/projects/${projectId}/files/${fileId}`);
  }

  async createProjectFile(projectId, data) {
    return await http.post(`/admin/projects/${projectId}/files`, {
      file: data,
    });
  }

  async updateProjectFile(projectId, fileId, data) {
    return await http.put(`/admin/projects/${projectId}/files/${fileId}`, {
      file: data,
    });
  }

  async deleteProjectFile(projectId, fileId) {
    return await http.delete(`/admin/projects/${projectId}/files/${fileId}`);
  }

  async getProposals() {
    return await http.get("/admin/proposal_tickets");
  }

  async updateProposal(proposalId, data) {
    return await http.put(`/admin/proposal_tickets/${proposalId}`, {
      proposal_ticket: data,
    });
  }
}

const adminService = new AdminService();
export default adminService;

import http from "../http-common";
import serviceUtil from "../utils/service-util"; // Ensure the import path is correct

class ProjectService {
  async newProject(data) {
    return await http.post("/projects", { project: data });
  }

  async updateProject(data) {
    const id = await serviceUtil.extrapolateFinalIdFromUrl();
    return http.patch(`/projects/${id}`, { project: data });
  }

  async getProjects() {
    return await http.get("/projects");
  }

  async getProject() {
    const id = serviceUtil.extrapolateFinalIdFromUrl();
    return http.get(`/projects/${id}`);
  }

  async newContact(contactData) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return await http.post(`/projects/${projectId}/contacts`, {
      contact: contactData,
    });
  }

  async getContacts() {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return await http.get(`/projects/${projectId}/contacts`);
  }

  async getContact(contactId) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return http.get(`/projects/${projectId}/contacts/${contactId}`);
  }

  async updateContact(contactId, contactData) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return await http.patch(`/projects/${projectId}/contacts/${contactId}`, {
      contact: contactData,
    });
  }

  async deleteContact(contactId) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return await http.delete(`/projects/${projectId}/contacts/${contactId}`);
  }

  async newLink(linkData) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return http.post(`/projects/${projectId}/project_links`, {
      project_link: linkData,
    });
  }

  async getLinks() {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return http.get(`/projects/${projectId}/project_links`);
  }

  async getLink(linkId) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return http.get(`/projects/${projectId}/project_links/${linkId}`);
  }

  async updateLink(linkId, linkData) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return http.patch(`/projects/${projectId}/project_links/${linkId}`, {
      project_link: linkData,
    });
  }

  async deleteLink(linkId) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return http.delete(`/projects/${projectId}/project_links/${linkId}`);
  }

  async getPhotos() {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return http.get(`/projects/${projectId}/images`);
  }

  async newPhoto(photoData) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    try {
      const response = await http.post(
        `/projects/${projectId}/images`,
        photoData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Failed to upload photo:", error);
      throw error;
    }
  }

  async deletePhoto(photoId) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    try {
      const response = await http.delete(
        `/projects/${projectId}/images/${photoId}`
      );
      return response;
    } catch (error) {
      console.error("Failed to delete photo:", error);
      throw error;
    }
  }

  async getFiles() {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    return http.get(`/projects/${projectId}/files`);
  }

  async newFiles(fileData) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    try {
      const response = await http.post(
        `/projects/${projectId}/files`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Failed to upload file:", error);
      throw error;
    }
  }

  async deleteFile(fileId) {
    const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
    try {
      const response = await http.delete(
        `/projects/${projectId}/files/${fileId}`
      );
      return response;
    } catch (error) {
      console.error("Failed to delete file:", error);
      throw error;
    }
  }

  async newTicket(ticketData) {
    return await http.post(`/projects/${ticketData.project_id}/tickets`, {
      ticket: ticketData,
    });
  }

  async getTickets(projectId) {
    return await http.get(`/projects/${projectId}/tickets`);
  }

  async getTicket(projectId, ticketId) {
    return await http.get(`/projects/${projectId}/tickets/${ticketId}`);
  }

  async updateTicket(projectId, ticketId, ticketData) {
    return await http.patch(`/projects/${projectId}/tickets/${ticketId}`, {
      ticket: ticketData,
    });
  }

  async deleteTicket(projectId, ticketId) {
    return await http.delete(`/projects/${projectId}/tickets/${ticketId}`);
  }

  async newProposalTicket(proposalTicketData) {
    return await http.post(
      `/projects/${proposalTicketData.project_id}/proposal_tickets`,
      { proposal_ticket: proposalTicketData }
    );
  }

  async getProposalTickets(projectId) {
    return await http.get(`/projects/${projectId}/proposal_tickets`);
  }

  async getProposalTicket(projectId, proposalTicketId) {
    return await http.get(
      `/projects/${projectId}/proposal_tickets/${proposalTicketId}`
    );
  }

  async getProposalTicketHistories(projectId, proposalTicketId) {
    return await http.get(
      `/projects/${projectId}/proposal_tickets/${proposalTicketId}/proposal_ticket_histories`
    );
  }

  async updateProposalTicket(projectId, proposalTicketId, proposalTicketData) {
    return await http.patch(
      `/projects/${projectId}/proposal_tickets/${proposalTicketId}`,
      { proposal_ticket: proposalTicketData }
    );
  }

  async deleteProposalTicket(projectId, proposalTicketId) {
    return await http.delete(
      `/projects/${projectId}/proposal_tickets/${proposalTicketId}`
    );
  }
}

const projectService = new ProjectService();
export default projectService;

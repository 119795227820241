import { useLocation, Navigate } from "react-router-dom";
import Cookies from "universal-cookie";


export function RequireAuth({ children }) {
    let cookies = new Cookies();    
    let user  = cookies.get("authorization");
    let location = useLocation();

    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return children;
    }
}


export function RequireNoAuth({ children }) {
    let cookies = new Cookies();    
    let user  = cookies.get("authorization");
    let location = useLocation();

    if (user) {
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
    } else {
        return children;
    }
}

function RequireAccountType({ children, requiredType }) {
    let cookies = new Cookies();    
    let accountType  = cookies.get("account_type");
    let location = useLocation();

    // Check both authentication and account type
    if (!accountType || accountType !== requiredType) {
        // Redirect to login if not authenticated or account type does not match
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return children;
    }
}


function RequireCompanyRole({ children, requiredRole }) {
    let cookies = new Cookies();    
    let companyRole = cookies.get("company_role");
    let location = useLocation();

    // Check both authentication and company role
    if (!companyRole || companyRole !== requiredRole) {
        // Redirect to login if not authenticated or company role does not match
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return children;
    }
}


export function RequireReferralPartner({ children }) {
    return <RequireAccountType children={children} requiredType="referral_partner" />;
}


export function RequireAdmin({ children }) {
    return <RequireCompanyRole children={children} requiredRole="full_access_admin" />;
}


export function RequireSuninSalesPartner({ children }) {
    return <RequireAccountType children={children} requiredType="sales_partner" />;
}

import React, { useState } from "react";
import authenticationService from "../services/authorization-services/auth.service";

export default function PasswordResetRequest() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage("");
    setIsError(false);
    try {
      await authenticationService.requestPasswordReset(email);
      setMessage(
        "If an account is associated with this email, you will receive a password reset link shortly."
      );
      setIsEmailSent(true);
    } catch (error) {
      setIsError(true);
      setMessage(error.message || "Failed to send reset link.");
    }
  };

  const handleResendClick = () => {
    setEmail("");
    setMessage("");
    setIsError(false);
    setIsEmailSent(false);
  };

  return (
    <div className="flex min-h-screen flex-1 items-center justify-center px-4 lg:px-8">
      <div className="w-full max-w-md px-2">
        <div className="bg-white py-8 px-4 shadow-md border border-gray-200 rounded-2xl">
          <div className="flex flex-col items-center">
            <img src="/suninn_logo.png" alt="Logo" className="w-16 mb-4" />
            {!isEmailSent && (
              <div className="text-center mb-10">
                <h2 className="text-3xl font-bold text-gray-900">
                  Password Reset Request
                </h2>
                <p className="text-gray-600 mt-2">
                  Enter your email address below.
                </p>
              </div>
            )}
            {isEmailSent ? (
              <div>
                <h1 className="text-xl font-semibold text-center text-gray-900">
                  Password Reset Link Sent
                </h1>
                <p className="text-sm text-center mt-2 text-green-600">
                  {message}
                </p>
                <button
                  onClick={handleResendClick}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 hover:text-indigo-700 focus:outline-none focus:border-indigo-700 focus:ring-2 focus:ring-indigo-500"
                >
                  Resend Email
                </button>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                <h1 className="text-xl font-semibold text-center text-gray-900">
                  Reset Password
                </h1>
                <p>
                  Please enter your email address to receive a password reset
                  link.
                </p>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Send Reset Link
                </button>
                {message && (
                  <p
                    className={`text-sm text-center mt-2 ${
                      isError ? "text-red-600" : "text-green-600"
                    }`}
                  >
                    {message}
                  </p>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

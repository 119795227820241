import Hero from "../../components/website/Hero";
import FrequentlyAskedQuestions from "../../components/website/FreequentlyAskedQuestions"
import AllInOnePlatform from "../../components/website/AllInOnePlatfrom.component"
import PlatformDemo from "../../components/website/PlatformDemo";          
import Pricing from "../../pages/website/Pricing";

function IndexPage() {
    return (
      <>
        <Hero />
        <AllInOnePlatform />
        <PlatformDemo />
        <Pricing />
        <FrequentlyAskedQuestions />
      </>
    );
  }
  
export default IndexPage;
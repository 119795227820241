import http from "../../http-common";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

class AuthenticationService {
    /**
     * Logs out the user by removing cookies and calling the server's logout endpoint.
     * Redirects to the home page after logout.
     */
    async logout() {
        const cookies = new Cookies();
        const bearerToken = cookies.get("authorization");

        // Remove cookies
        cookies.remove("authorization", { path: '/' });
        cookies.remove("username", { path: '/' });
        cookies.remove("account_type", { path: '/' });
        cookies.remove("company_role", { path: '/' });
        cookies.remove("email", { path: '/' });
        cookies.remove("id", { path: '/' });

        // Call logout endpoint
        await http.delete("/logout", {
            headers: {
                Authorization: bearerToken,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
        });
        window.location.assign('/');
    }

    /**
     * Logs in the user by sending their credentials to the server.
     * Sets authorization cookies and redirects to the home page upon successful login.
     * @param {Object} data - User login data.
     */
    async login(data) {
        console.log('LOGIN FUNCTION HIT');
        try {
            const response = await http.post(`/login`, { "user": data }, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
            });

            const cookies = new Cookies();
            const bearerToken = response.headers["authorization"];
            const accountType = response.data.status.data.user.account_type;
            const companyRole = response.data.status.data.user.company_role;

            if (bearerToken !== undefined) {
                // Set cookies with expiration
                cookies.set('authorization', bearerToken, { path: '/', expires: new Date(Date.now() + 2 * 86400 * 1000) });
                cookies.set('account_type', accountType, { path: '/', expires: new Date(Date.now() + 2 * 86400 * 1000) });
                cookies.set('company_role', companyRole, { path: '/', expires: new Date(Date.now() + 2 * 86400 * 1000) });

                window.location.href = '/home'; // Redirect on success
            }
        } catch (error) {
            throw error;
        }
    }

    /**
     * Signs up a new user by sending their data to the server.
     * Sets authorization cookies and redirects based on the account type.
     * @param {Object} data - User signup data.
     * @param {boolean} isFreeAccount - Indicates if the account is free.
     */
    async signUp(data, isFreeAccount) {
        try {
            const response = await http.post("/signup", { "user": data });
            const cookies = new Cookies();
            const bearerToken = response.headers["authorization"];
            const accountType = response.data.data.account_type;
            const companyRole = response.data.data.company_role;

            if (bearerToken !== undefined) {
                cookies.set('authorization', bearerToken, { path: '/', expires: new Date(Date.now() + (2 * 24 * 60 * 60 * 1000)) });
                cookies.set('account_type', accountType, { path: '/', expires: new Date(Date.now() + (2 * 24 * 60 * 60 * 1000)) });
                cookies.set('company_role', companyRole, { path: '/', expires: new Date(Date.now() + 2 * 86400 * 1000) });

                isFreeAccount ? window.location.href = '/home' : window.location.href = '/checkout';
            }
        } catch (error) {
            throw error;
        }
    }

    /**
     * Edits user information by sending updated data to the server.
     * Sets a new authorization cookie and reloads the page upon success.
     * @param {Object} data - Updated user data.
     */
    async editUser(data) {
        try {
            const response = await http.post("/signup/edit", { "user": data });
            const cookies = new Cookies();
            const bearerToken = response.headers["authorization"];
            if (bearerToken !== undefined) {
                cookies.set('authorization', bearerToken, { path: '/', expires: new Date(Date.now() + (2 * 24 * 60 * 60 * 1000)) });
                window.location.reload();
            }
        } catch (error) {
            alert("Failed with error: " + error);
        }
    }

    /**
     * Checks if a username is valid by querying the server.
     * @param {string} user_name - The username to validate.
     * @returns {Promise} The server response.
     */
    async usernameIsValid(user_name) {
        return await http.get("username-is-valid", { params: { user_name } });
    }

    /**
     * Verifies a user's email using a token.
     * @param {string} token - The verification token.
     * @returns {Promise} The server response data.
     */
    async verifyEmail(token) {
        try {
            const response = await http.get(`/verify_email?token=${token}`);
            return response.data;
        } catch (error) {
            console.error("Error verifying email:", error);
            throw error;
        }
    }

    /**
     * Checks if an email is valid by querying the server.
     * @param {string} email - The email to validate.
     * @returns {Promise} The server response.
     */
    async emailIsValid(email) {
        return await http.get("email-is-valid", { params: { email } });
    }

    /**
     * Fetches the user profile from the server.
     * @returns {Promise} The user profile data.
     */
    async getUserProfile() {
        const cookies = new Cookies();
        const bearerToken = cookies.get("authorization");

        try {
            const response = await http.get("/user/profile", {
                headers: {
                    Authorization: bearerToken,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error("Failed to fetch user profile:", error);
            throw error;
        }
    }

    /**
     * Updates the user profile by sending updated data to the server.
     * @param {Object} data - Updated user data.
     * @returns {Promise} The server response.
     */
    async updateUserProfile(data) {
        try {
            const cookies = new Cookies();
            const bearerToken = cookies.get("authorization");

            const response = await http.post("/user/profile/edit", { "user": data }, {
                headers: {
                    Authorization: bearerToken,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
            });
            return response;
        } catch (error) {
            console.error("Failed to update user profile:", error);
            throw error;
        }
    }

    /**
     * Changes the user's password by sending the new password data to the server.
     * Sets a new authorization cookie upon success.
     * @param {Object} data - New password data.
     */
    async changePassword(data) {
        try {
            const response = await http.post("/password/edit", { "data": data });
            const cookies = new Cookies();
            const bearerToken = response.headers["authorization"];
            if (bearerToken !== undefined) {
                cookies.set('authorization', bearerToken, { path: '/', expires: new Date(Date.now() + (2 * 24 * 60 * 60 * 1000)) });
            }
        } catch (error) {
            alert("Failed with error: " + error);
        }
    }

    /**
     * Requests a password reset by sending the user's email to the server.
     * Displays a success or error toast notification based on the result.
     * @param {string} email - The email address for the password reset request.
     */
    async requestPasswordReset(email) {
        try {
            await http.post('/password', { user: { email } });
            toast.success("Check your email for the reset link.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
        } catch (error) {
            toast.error("Failed to send reset link.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
        }
    }

    async resetPassword(user) {
        try {
            const response = await http.patch('/password/', { user }); // Ensure the parameters are nested under the 'user' key
            toast.success("Password has been reset successfully.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            return response.data; // Return the response data
        } catch (error) {
            toast.error("Failed to reset password.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            throw error;
        }
    }
    
}

const authenticationService = new AuthenticationService();
export default authenticationService;

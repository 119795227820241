import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import leadService from '../../services/leads.service'; 
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import toast from 'react-hot-toast';

export default function ViewLeadPage() {
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [address_1, setAddressOne] = useState('');
    const [address_2, setAddressTwo] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');

    useEffect(() => {
        fetchLead();
        fetchFiles();
    }, []);

    const fetchLead = async () => {
        try {
            const response = await leadService.getLead();
            setCompanyName(response.data.company_name || '');
            setAddressOne(response.data.address_1 || '');
            setAddressTwo(response.data.address_2 || '');
            setCity(response.data.city || '');
            setState(response.data.state || '');
            setZip(response.data.Zip || '');
            setFirstName(response.data.first_name || '');
            setLastName(response.data.last_name || '');
            setEmail(response.data.email || '');
            setPhoneNumber(response.data.phone_number || '')
            setZip(response.data.zip || '')
      } catch (err) {
            toast.error('Failed to fetch Referral.');
            console.error(err);
      }
      setIsLoading(false);
    };

    const fetchFiles = async () => {
        setIsLoading(true);
        try {
            const response = await leadService.getFiles();
            setFiles(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error("Failed to fetch photos:", error);
            toast.error('Failed to fetch photos.');
            setIsLoading(false);
        }
    };

    const downloadFile = (photoUrl, fileName) => {
        const link = document.createElement('a');
        link.href = photoUrl;
        link.download = fileName;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 mt-5">
             <nav aria-label="Breadcrumb" className="mb-5">
                <Link to="/referrals" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
                    <ChevronLeftIcon className="h-5 w-5 mr-1 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    Back to all Referrals
                </Link>
            </nav>
              <div className="grid grid-cols-1 gap-6 ">

                  {/* Company and Address Card */}
                  <div className="bg-white p-6 shadow shadow-lg border border-gray-100 rounded-lg">
                      <h2 className="text-lg font-semibold mb-2">Company Information</h2>
                      <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                          <dt className="font-medium text-gray-500">Company:</dt>
                          <dd>{company_name}</dd>
                          <dt className="font-medium text-gray-500">Address:</dt>
                          <dd>{`${address_1}, ${address_2}`}</dd>
                          <dt className="font-medium text-gray-500">City:</dt>
                          <dd>{city}</dd>
                          <dt className="font-medium text-gray-500">State:</dt>
                          <dd>{state}</dd>
                          <dt className="font-medium text-gray-500">Zip:</dt>
                          <dd>{zip}</dd>
                      </dl>
                  </div>

                  {/* Contact Information Card */}
                  <div className="bg-white p-6 shadow shadow-lg border border-gray-100 rounded-lg">
                      <h2 className="text-lg font-semibold mb-2">Contact Information</h2>
                      <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                          <dt className="font-medium text-gray-500">Name:</dt>
                          <dd>{`${first_name} ${last_name}`}</dd>
                          <dt className="font-medium text-gray-500">Email:</dt>
                          <dd>{email}</dd>
                          <dt className="font-medium text-gray-500">Phone:</dt>
                          <dd>{phone_number}</dd>
                      </dl>
                  </div>

                  {/* Files and Photos Card */}
                  <div className="bg-white p-6 shadow border border-gray-100 rounded-lg mb-12">
                        <h2 className="text-lg font-semibold mb-2">Files & Photos</h2>
                        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-6">
                        {files.map((file, index) => {
                        const isImage = file.content_type.startsWith('image');
                        return isImage ? (
                            <div key={index} className="bg-white shadow-xl rounded-lg overflow-hidden border border-gray-200">
                                <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-t-lg bg-gray-100">
                                    <img className="w-full h-full object-cover" src={file.url} alt={`${index + 1}`} />
                                </div>
                                <div className="p-4 flex justify-between items-center">
                                    <button 
                                    onClick={() => downloadFile(file.url, `${file.name}-${index}.jpg`)} 
                                    className="flex items-center text-blue-600 hover:text-blue-700 transition-colors duration-150 ease-in-out">
                                        <svg className="w-4 h-4 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M4 4v16c0 1.1.9 2 2 2h12c1.1 0 2-0.9 2-2V4H4z"></path>
                                            <polyline points="16 2 16 6 8 6 8 2"></polyline>
                                            <line x1="12" y1="11" x2="12" y2="17"></line>
                                            <polyline points="9 14 12 17 15 14"></polyline>
                                        </svg>
                                        Download
                                    </button>
                                </div>
                            </div>
                            ) : (
                            <div key={index} className="flex flex-col bg-white shadow-md rounded-lg overflow-hidden border border-gray-300 hover:shadow-lg transition-shadow duration-200 ease-in-out">
                                <div className="flex-grow p-4 bg-gray-50">
                                    <h3 className="text-lg font-bold text-gray-800 text-center">{file.name || `File: ${index + 1}`}</h3>
                                </div>
                                <div className="mt-auto p-4 flex justify-between items-center">
                                    <button onClick={() => downloadFile(file.url, `file-${index}`)} className="btn-download flex items-center text-blue-600 hover:text-blue-700 transition-colors duration-150 ease-in-out">
                                        <svg className="w-4 h-4 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M4 4v16c0 1.1.9 2 2 2h12c1.1 0 2-0.9 2-2V4H4z"></path>
                                            <polyline points="16 2 16 6 8 6 8 2"></polyline>
                                            <line x1="12" y1="11" x2="12" y2="17"></line>
                                            <polyline points="9 14 12 17 15 14"></polyline>
                                        </svg>
                                        Download
                                    </button>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
import React, { useState, useEffect, useCallback } from 'react';
import isEmail from 'validator/lib/isEmail';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import authenticationService from '../../services/authorization-services/auth.service'; 


const Signup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [authCookie, setAuthCookie] = useState('');
  const [validFields, setValidFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [referral, setReferral] = useState('no');
  const [referrerName, setReferrerName] = useState('');
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [user_name, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [validUserName, setValidUserName] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [address_1, setAddress1] = useState("");
  const [address_2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [validAddress1, setValidAddress1] = useState(false);
  const [validCity, setValidCity] = useState(false);
  const [validState, setValidState] = useState(false);
  const [validZip, setValidZip] = useState(false);
  const [partnershipType, setPartnershipType] = useState("");
  const [validPartnershipType, setValidPartnershipType] = useState(false)
  const [agreeToTerms, setAgreeToTerms] = useState(true);
  const [agreeToPrivacy, setAgreeToPrivacy] = useState(true);
  const [agreeToMarketing, setAgreeToMarketing] = useState(true);
  const validateEmail = email => /\S+@\S+\.\S+/.test(email);
  const validatePhoneNumber = number => /^\d{10}$/.test(number);
  const isNonEmpty = text => text.trim().length > 0;
  
  const handleFirstNameChange = value => {
    setFirstName(value);
    setValidFirstName(isNonEmpty(value));
  };

  const handleLastNameChange = value => {
    setLastName(value);
    setValidLastName(isNonEmpty(value));
  };
  
  const handleUserNameChange = value => {
    setUserName(value);
    setValidUserName(isNonEmpty(value));
  };
  
  const handleEmailChange = value => {
    setEmail(value);
    checkValidEmail(value);
  };
  
  const checkValidEmail = useCallback(debounce(async (email) => {
    const lowerCaseEmail = toLowerCase(email);
    if (lowerCaseEmail.length > 0 && isEmail(lowerCaseEmail)) {
      try {
        const response = await authenticationService.emailIsValid(lowerCaseEmail);
        setEmailTaken(!response.data.validEmail);
        setValidEmail(response.data.validEmail);
      } catch (error) {
        console.error("Error validating email:", error);
        setEmailTaken(true); 
        setValidEmail(false);
      }
    } else {
      setValidEmail(false);
    }
  }, 500), []);
  
  const handleReferralChange = (event) => {
    setReferral(event.target.value);
  };

  const handleReferrerNameChange = (event) => {
      setReferrerName(event.target.value);
  };
  
  const handlePhoneNumberChange = value => {
    setPhoneNumber(value);
    setValidPhoneNumber(validatePhoneNumber(value));
  };
  
  const handlePasswordChange = value => {
    setPassword(value);
    setValidPassword(value.length >= 8); 
  };
  
  const handlePasswordConfirmationChange = value => {
    setPasswordConfirmation(value);
    setValidPassword(value === password && value.length >= 8);
  }

  const handleAddress1Change = value => {
    setAddress1(value);
    setValidAddress1(isNonEmpty(value));
  };
  

  const handleCityChange = value => {
    setCity(value);
    setValidCity(isNonEmpty(value));
  };
  
  const handleStateChange = value => {
    setState(value);
    setValidState(isNonEmpty(value));
  };
  
  const handleZipChange = value => {
    setZip(value);
    setValidZip(/^\d{5}(-\d{4})?$/.test(value));
  };
  
  const handlePartnershipChange = (event) => {
    setPartnershipType(event.target.value);
    console.log(event.target.value)
    setValidPartnershipType(true); 
  };

  const handleTermsChange = (e) => setAgreeToTerms(e.target.checked);
  const handlePrivacyChange = (e) => setAgreeToPrivacy(e.target.checked);
  const handleMarketingChange = (e) => setAgreeToMarketing(e.target.checked);

  function capitalizeName(name) {
    return name.trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }

  function formatState(state) {
    if (state.length === 2) { 
      return state.toUpperCase();
    } else {
      return capitalizeWords(state);
    }
  }
  
  function toLowerCase(string) {
    return string.toLowerCase();
  }

  function capitalizeWords(string) {
    return string.trim().split(' ')
                 .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                 .join(' ');
  }

  useEffect(() => {
    const allValid = validFirstName && validLastName && validUserName && validEmail && !emailTaken &&
                     validPhoneNumber && validPassword && validAddress1 && validCity && validState &&
                     validZip && validPartnershipType;

    setValidFields(allValid);

  }, [validFirstName, validLastName, validUserName, validEmail, emailTaken,
      validPhoneNumber, validPassword, validAddress1, validCity, validState,
      validZip, validPartnershipType]);
  
  const handleSignUp = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    const formattedFirstName = capitalizeWords(first_name);
    const formattedLastName = capitalizeWords(last_name);
    const formattedCity = capitalizeWords(city);
    const formattedState = formatState(state);
    const formattedUserName = toLowerCase(user_name);
    const formattedEmail = toLowerCase(email);

    if (!validFields) {
      setErrorMessage('Please ensure all fields are filled correctly.');
      return;
    }

    const data = {
      first_name: formattedFirstName, 
      last_name: formattedLastName, 
      user_name: formattedUserName, 
      email: formattedEmail, 
      password, 
      password_confirmation: passwordConfirmation, 
      phone_number,
      referrer_name: referrerName,
      address_1: toLowerCase(address_1),
      address_2: toLowerCase(address_2),
      city: formattedCity,
      state: formattedState,
      zip 
    };
  
    try {
      const isFree = partnershipType === "referral_partner";
      const response = await authenticationService.signUp(data, isFree);
      setAuthCookie(response);
    } catch (error) {
      const message = error?.response?.data?.message || "An unexpected error occurred. Please try again later.";
      setErrorMessage(message);
    }
  };
    
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
    window.scrollTo(0, 0);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  const partnershipOptions = [
    {
      id: "referral_partner",
      title: "Referral Partner",
      description: "Join us as a Referral Partner and earn commissions for your referrals. This opportunity is perfect for those who prefer not to be fully involved in the sales cycle.",
      price: "Free"
    },
    {
      id: "sales_partner",
      title: "Sales Partner",
      description: "Perfect for professionals looking to expand into commercial solar sales, this role offers full involvement in every project. As a sales partner, you can earn up to 70% of the net profit.",
      price: "$129/month"
    }
  ];
  
  const totalSteps = partnershipType === 'sales_partner' ? 5 : 4;

  return (
    <div className="flex min-h-full items-center justify-center px-4 py-12 mb-12">
      <div className="w-full max-w-md px-2 sm:px-4">
        <div className="bg-white py-8 px-4 sm:px-6 shadow-md border border-gray-200 rounded-3xl">
          <div className="text-center">
            {currentStep === 1 ? (
              <>
                <img src="/suninn_logo.png" alt="Favicon" className="mx-auto w-16" />
                <h2 className="mt-6 text-2xl font-extrabold text-gray-900">Select a partnership plan: </h2>
              </>
            ) : currentStep === 2 ? (
              <>
                <img src="/suninn_logo.png" alt="Favicon" className="mx-auto w-12 h-12" />
                <h2 className="mt-6 text-2xl font-extrabold text-gray-900 ">Did someone refer you?</h2>
                <p className="mt-2 text-sm text-gray-600">Please enter the full name of the individual who guided you here so we can reward them.</p>
                
              </>
              ) : currentStep === 3 ? (
                <>
                  <img src="/suninn_logo.png" alt="Favicon" className="mx-auto w-12 h-12" />
                  <h2 className="mt-6 text-2xl font-extrabold text-gray-900 ">Personal Information</h2>
                  <p className="mt-2 text-sm text-gray-600">Please enter your personal details.</p>
                </>
              ) : (
              <>
                <img src="/suninn_logo.png" alt="Favicon" className="mx-auto w-12 h-12" />
                <h2 className="mt-6 text-2xl font-extrabold text-gray-900">Address Details</h2>
                <p className="mt-2 text-sm text-gray-600">Your address information is needed to complete the registration.</p>
              </>
            )}
          </div>
          
          {errorMessage && <p className="mt-4 text-sm text-red-600 text-center">{errorMessage}</p>}
              
          <form className="mt-8 space-y-6" onSubmit={handleSignUp}> 

            {validPartnershipType && (
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${(currentStep / totalSteps) * 100}%` }}></div>
              </div>
            )}
             
              {currentStep === 1 && (
                <>
                  <div className="mt-8">
                    <div className="grid grid-cols-1 gap-4">
                      {partnershipOptions.map(option => (
                        <label key={option.id} className={`p-4 border rounded-lg shadow-sm cursor-pointer hover:bg-blue-100 ${partnershipType === option.id ? "bg-blue-50 border-blue-400" : "bg-white border-gray-300"}`}>
                          <input
                            type="radio"
                            name="partnershipType"
                            value={option.id}
                            checked={partnershipType === option.id}
                            onChange={handlePartnershipChange}
                            className="sr-only"
                          />
                          <div className="flex flex-col items-start">
                            <h3 className="text-lg font-medium text-gray-900">{option.title}</h3>
                            <p className="text-gray-500 my-1">{option.description}</p>
                            <p className="font-semibold text-gray-900">{option.price}</p>
                          </div>
                          {partnershipType === option.id && (
                            <span className="self-center mt-2 rounded-full bg-blue-500 text-white px-4 py-1 text-xs">Selected</span>
                          )}
                        </label>
                      ))}
                    </div>

                    <div className="flex justify-center mt-4 mb-6">
                      {validPartnershipType && (
                        <div className="mt-6">
                          <div className="grid grid-cols-1 gap-3 mb-8">
                            <label className="inline-flex items-center p-2 bg-white rounded-lg shadow-sm hover:bg-gray-50 cursor-pointer">
                              <input type="checkbox" className="form-checkbox h-4 w-4 text-blue-600 focus:ring-blue-500 focus:border-blue-500" checked={agreeToTerms} onChange={handleTermsChange} />
                              <span className="ml-2 text-gray-700 text-sm font-medium">I agree to the <a href="/terms-of-service" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 underline">Terms of Service</a></span>
                            </label>
                            <label className="inline-flex items-center p-2 bg-white rounded-lg shadow-sm hover:bg-gray-50 cursor-pointer">
                              <input type="checkbox" className="form-checkbox h-4 w-4 text-blue-600 focus:ring-blue-500 focus:border-blue-500" checked={agreeToPrivacy} onChange={handlePrivacyChange} />
                              <span className="ml-2 text-gray-700 text-sm font-medium">I agree to the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 underline">Privacy Policy</a></span>
                            </label>
                            <label className="inline-flex items-center p-2 bg-white rounded-lg shadow-sm hover:bg-gray-50 cursor-pointer">
                              <input type="checkbox" className="form-checkbox h-4 w-4 text-blue-600 focus:ring-blue-500 focus:border-blue-500" checked={agreeToMarketing} onChange={handleMarketingChange} />
                              <span className="ml-2 text-gray-700 text-sm font-medium">I consent to receive marketing communications</span>
                            </label>
                          </div>

                          {agreeToTerms && agreeToPrivacy && agreeToMarketing && (
                            <div className="flex justify-end mt-6 mb-6">
                              
                              <button
                              type="button"
                              onClick={nextStep}
                              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              >
                              Next
                              </button>

                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {currentStep === 2 && (
                <div className="mt-8">
                  <div className="grid grid-cols-1 gap-4">
                    <div>
                      <label htmlFor="referralDropdown" className="block text-sm font-medium text-gray-700 mt-4">Did someone refer you?</label>
                      <select 
                        id="referralDropdown" 
                        value={referral} 
                        onChange={handleReferralChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                      </select>

                      {referral === 'yes' && (
                        <div className="mt-4">
                          <label htmlFor="referrerName" className="block text-sm font-medium text-gray-700">Full Name of Referrer</label>
                          <input
                            type="text"
                            id="referrerName"
                            name="referrerName"
                            value={referrerName}
                            onChange={handleReferrerNameChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      )}
                      <div className="flex justify-between mt-6 mb-6">
                        <button
                          type="button"
                          onClick={prevStep}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          onClick={nextStep}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 3 &&
                <>
                  <div>
                    <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">First Name</label>
                    <div className="mt-1">
                      <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        required
                        placeholder="First Name"
                        value={first_name}
                        onChange={e => handleFirstNameChange(e.target.value)}
                        className={`block w-full rounded-md ${validFirstName ? "border-gray-300" : "border-red-500"} shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                    <div className="mt-1">
                      <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        required
                        placeholder="Last Name"
                        value={last_name}
                        onChange={e => handleLastNameChange(e.target.value)}
                        className={`block w-full rounded-md ${validLastName ? "border-gray-300" : "border-red-500"} shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="user_name" className="block text-sm font-medium leading-6 text-gray-900">Username</label>
                    <div className="mt-1">
                      <input
                        id="user_name"
                        name="user_name"
                        type="text"
                        required
                        placeholder="Username"
                        value={user_name}
                        onChange={e => handleUserNameChange(e.target.value)}
                        className={`block w-full rounded-md ${validUserName ? "border-gray-300" : "border-red-500"} shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        required
                        placeholder="Email"
                        value={email}
                        onChange={e => handleEmailChange(e.target.value)}
                        className={`block w-full rounded-md ${!emailTaken && validateEmail(email) ? "border-gray-300" : "border-red-500"} shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                      />
                      {emailTaken && <p className="text-sm text-red-600 mt-1 ">Email already taken.</p>}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="phone_number" className="block text-sm font-medium leading-6 text-gray-900">Phone Number</label>
                    <div className="mt-1">
                      <input
                        id="phone_number"
                        name="phone_number"
                        type="text"
                        required
                        placeholder="Phone Number"
                        value={phone_number}
                        onChange={e =>  handlePhoneNumberChange(e.target.value)}
                        className={`block w-full rounded-md ${validPhoneNumber ? "border-gray-300" : "border-red-500"} shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                        />
                       {!validPhoneNumber && <p className="text-sm text-red-600 mt-1 ">Phone number must be 10 digits only.</p>}
                      </div>
                  </div>
      
                  <div>
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        required
                        placeholder="Password"
                        value={password}
                        onChange={e => handlePasswordChange(e.target.value)}
                        className={`block w-full rounded-md ${validPassword ? "border-gray-300" : "border-red-500"} shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 sm:text-sm`}
                      />
                    </div>
                  </div>
      
                  <div>
                    <label htmlFor="confirm_password" className="block text-sm font-medium leading-6 text-gray-900">Confirm Password</label>
                    <div className="mt-1">
                      <input
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        required
                        placeholder="Confirm Password"
                        value={passwordConfirmation}
                        onChange={e => handlePasswordConfirmationChange(e.target.value)}
                        className={`block w-full rounded-md ${passwordConfirmation === password && passwordConfirmation.length >= 8 ? "border-gray-300" : "border-red-500"} shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between mt-6 mb-6">
                      <button
                        type="button"
                        onClick={prevStep}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      >
                        Previous
                      </button>
                
                      {validFirstName && validLastName && validUserName 
                        && validEmail && !emailTaken && validPhoneNumber 
                        && validPassword && (password === passwordConfirmation) && (
                          <button
                            type="button"
                            onClick={nextStep}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Next
                          </button>
                      )}
                    </div>
                  </div>
                </> 
              }

              {currentStep === 4 && 
                <>
                  <div>
                    <label htmlFor="address_1" className="block text-sm font-medium text-gray-900">Address Line 1</label>
                    <input
                        id="address_1"
                        name="address_1"
                        type="text"
                        required
                        placeholder="1234 Main St"
                        value={address_1}
                        onChange={e => handleAddress1Change(e.target.value)}
                        className={`mt-1 block w-full rounded-md ${validAddress1 ? "border-gray-300" : "border-red-500"} shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                      />
                  </div>
                
                  <div>
                    <label htmlFor="address_2" className="block text-sm font-medium text-gray-900">Address Line 2</label>
                    <input
                      id="address_2"
                      name="address_2"
                      type="text"
                      placeholder="Apartment, studio, or floor"
                      value={address_2}
                      onChange={e => setAddress2(e.target.value)}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                    />
                  </div>
              
                  <div>
                    <label htmlFor="city" className="block text-sm font-medium text-gray-900">City</label>
                    <input
                      id="city"
                      name="city"
                      type="text"
                      required
                      placeholder="City"
                      value={city}
                      onChange={e => handleCityChange(e.target.value)}
                      className={`mt-1 block w-full rounded-md ${validCity ? "border-gray-300" : "border-red-500"} shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                    />
                  </div>
        
                  <div>
                    <label htmlFor="state" className="block text-sm font-medium text-gray-900">State</label>
                    <input
                      id="state"
                      name="state"
                      type="text"
                      required
                      placeholder="State"
                      value={state}
                      onChange={e => handleStateChange(e.target.value)}
                      className={`mt-1 block w-full rounded-md ${validState ? "border-gray-300" : "border-red-500"} shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                    />
                  </div>
              
                  <div>
                    <label htmlFor="zip" className="block text-sm font-medium text-gray-900">ZIP Code</label>
                    <input
                      id="zip"
                      name="zip"
                      type="text"
                      required
                      placeholder="ZIP Code"
                      value={zip}
                      onChange={e => handleZipChange(e.target.value)}
                      className={`mt-1 block w-full rounded-md ${validZip ? "border-gray-300" : "border-red-500"} shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
                    />
                  </div>
              
                  <div>
                    <div className="flex justify-between mt-6 mb-6">
                      <button
                        type="button"
                        onClick={prevStep}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      >
                        Previous
                      </button>
                      {validAddress1 && validCity && validState && validZip && (
                        partnershipType === 'referral_partner' ? (
                          <button
                            type="button"
                            onClick={handleSignUp}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          >
                            Sign Up!
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={handleSignUp}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Next
                          </button>
                        )
                      )}
                    </div>
                  </div> 
                </>
              }
          </form>
          <div className="text-sm">
            <p> 
              {'Already have an account? '}
              <Link to="/login" className="font-semibold text-blue-600 hover:text-blue-500">Login</Link>
            </p>
          </div>
        </div>  
      </div>
    </div>
  );
};

export default Signup;






import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebarHeader from './AdminHeader';

const AdminLayout = () => {
  return (
    <AdminSidebarHeader inner_content={
      <>
        <main>
          <Outlet /> {/* This is where nested routes will be rendered */}
        </main>
       
      </>
    }/>
  );
};

export default AdminLayout;

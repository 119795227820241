import { Link } from 'react-router-dom';
import { PlusIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from "react";
import leadService from "../../services/leads.service";
import NewLeadModal from '../../components/leads/NewLead.component';

export default function Referrals() {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage, setLeadsPerPage] = useState(8);
  const [isNewLeadModalOpen, setIsNewLeadModalOpen] = useState(false); 

  const fetchLeads = async () => {
    setLoading(true);
    const response = await leadService.getLeads();
    if (response && response.data) {
      const sortedLeads = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      setLeads(sortedLeads);
    } else {
      console.error('Failed to fetch leads');
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchLeads();
  }, []);

  const filteredLeads = leads.filter((lead) => currentFilter === 'All' || lead.lead_status === currentFilter);
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);
  const totalPages = Math.ceil(filteredLeads.length / leadsPerPage);

  const handlePreviousClick = () => {
    setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, totalPages));
  };

  const startResult = (currentPage - 1) * leadsPerPage + 1;
  const endResult = Math.min(startResult + leadsPerPage - 1, filteredLeads.length);

  return (
    <div>
      <NewLeadModal 
        isModalOpen={isNewLeadModalOpen}
        setIsModalOpen={setIsNewLeadModalOpen}
        fetchLeads={fetchLeads} 
      />

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5">
        <nav className="sm:hidden">
          <Link to="/home" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Home
          </Link>
        </nav>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <Link to="/home" className="text-sm font-medium text-gray-500 hover:text-gray-700">Home</Link>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Referrals</span>
              </div>
            </li>
          </ol>
        </nav>
      </div>

      <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 mt-5">
        <div className="rounded-xl bg-white px-5 py-6 shadow shadow-2xl sm:px-6">
          <div className="flex min-h-full flex-col">
            <div className="mt-8">
              <div className="mb-4 flex items-center justify-between">
                <h3 className="text-xl font-semibold text-gray-900">Referrals</h3>
                <button
                  onClick={() => setIsNewLeadModalOpen(true)}
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-300 px-5 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                >
                  <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Referral
                </button>
              </div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 table-fixed">
                      <thead>
                        <tr>
                          <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Company</th>
                          <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell"><span className="hidden sm:inline-flex">Primary&nbsp;</span>Contact</th>
                          <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden md:table-cell">Date Created</th>
                          <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Status</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {currentLeads.length > 0 ? (
                          currentLeads.map((lead) => (
                            <tr key={lead.id} className="even:bg-gray-50">
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                {lead.company_name}
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 hidden sm:table-cell">
                                {`${lead.first_name} ${lead.last_name}`}
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:table-cell">
                                {new Date(lead.created_at).toLocaleDateString()}
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-md text-gray-500">
                                <Link to={`/referrals/${lead.id}`} className="text-blue-600 hover:text-blue-800 visited:text-purple-600">
                                  View
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center py-4 text-sm text-gray-500">
                              No Referrals Yet
                            </td>
                          </tr>
                        )}
                        {currentLeads.length < 10 && [...Array(10 - currentLeads.length)].map((_, index) => (
                          <tr key={`empty-${index}`} className="even:bg-gray-50">
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <nav
                      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                      aria-label="Pagination"
                    >
                      <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                          Showing <span className="font-medium">{startResult}</span> to <span className="font-medium">{endResult}</span> of{' '}
                          <span className="font-medium">{filteredLeads.length}</span> results
                        </p>
                      </div>
                      <div className="flex flex-1 justify-between sm:justify-end">
                        <button
                          onClick={handlePreviousClick}
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Previous
                        </button>
                        <button
                          onClick={handleNextClick}
                          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Next
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const stats = [
  { label: 'Global Installed Solar Capacity', value: '1,344.54 GW in 2023' },
  { label: 'Global Solar Market CAGR (2024-2032)', value: '7.1%' },
  { label: 'Global Solar Cost Reduction', value: 'Over 80% since 2010' },
  { label: 'MEA Solar Market Growth Rate', value: 'Highest from 2022 to 2030' }
]

const values = [
  {
    name: 'Integrity',
    description: 'We uphold the highest standards of integrity, ensuring honesty and transparency in all interactions with our community of solar professionals.'
  },
  {
    name: 'Intelligence',
    description: 'Our platform is driven by intelligence, using smart data and analytics to empower our members with state-of-the-art solar solutions.'
  },
  {
    name: 'Innovation',
    description: 'We prioritize innovation, continuously advancing our technology to meet the dynamic needs of the solar industry.'
  },
  {
    name: 'Intuitiveness',
    description: 'Our interfaces are designed with intuitiveness in mind, making technology accessible and functional for all members.'
  },
  {
    name: 'Inclusivity',
    description: 'We foster an inclusive environment, encouraging knowledge sharing and collaboration among members to drive collective success.'
  },
  {
    name: 'Inspiration',
    description: 'We aim to inspire our members by providing ongoing education and opportunities to grow, keeping them at the forefront of the industry.'
  }
]


export default function Mission() {

  return (
    <div className="bg-white">
      {/* Header */}
     
      <main className="isolate">
        {/* Hero section */}
        <div className="relative isolate -z-10">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    We are spearheading a brighter, more sustainable future.
                  </h1>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  At Sunin Energy, our vision is to drive the global transition towards clean, renewable, and sustainable energy solutions. 
                  We stand at the forefront of this transformative movement, leveraging our strong partnerships with influential leaders in 
                  the renewable energy sector. Our unwavering commitment and innovative approach position us to emerge as the preeminent 
                  commercial solar fulfillment and sales platform, spearheading a brighter, more sustainable future for all.
                  </p>
                </div>
                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        src="images/AdobeStock_257376811_Preview.jpeg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        src="images/shutterstock_2402636233.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="/images/AdobeStock_273693667.jpeg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        src="images/shutterstock_2112459077.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="images/shutterstock_2138825563.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content section */}
        <div className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our mission</h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="text-xl leading-8 text-gray-600">
                  At Sunin Energy, we are dedicated to transforming the renewable energy landscape for small to 
                  medium-sized commercial businesses. We recognize the unique challenges and opportunities these 
                  enterprises face in adopting sustainable practices. Our mission is to equip these businesses with
                  tailor-made solar solutions that not only meet their specific needs but also enhance their 
                  operational efficiency and sustainability.


                </p>
                <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                  <p>
                    We are committed to making renewable energy accessible and manageable 
                    for this vital segment of the economy. Through our comprehensive services—ranging 
                    from precision design and expert engineering to customized financing and meticulous 
                    project management—we ensure a seamless transition to solar power. Our approach is 
                    designed to minimize disruptions, maximize cost savings, and increase energy 
                    independence for each client.
                  </p>
                  <p className="mt-10">
                    By fostering strong partnerships and providing continuous support and education, 
                    we help businesses understand and leverage the benefits of solar energy. Our goal 
                    is to empower these enterprises to not only thrive in a competitive market but also 
                    to become leaders in sustainability within their communities.
                  </p>
                </div>
              </div>
              <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                  {stats.map((stat) => (
                    <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                      <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                      <dd className="text-5xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>

        {/* Image section */}
        <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
          <img
            // src="https://images.unsplash.com/photo-1529156069898-49953e39b3ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
            src="images/AdobeStock_482753913.jpeg"
            alt=""
            className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
          />
        </div>

        {/* Values section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our values</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            The logo of Sunin Energy symbolizes our commitment to core values like Integrity, Intelligence, Innovation, Intuitiveness, Inclusivity, and Inspiration. Each 'in' in our branding underscores our dedication to advancing intelligent, innovative solar solutions that empower and inspire our commercial clients.            </p>
          </div>
          <dl className="mx-auto mb-12 mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {values.map((value) => (
              <div key={value.name}>
                <dt className="font-semibold text-gray-900">{value.name}</dt>
                <dd className="mt-1 text-gray-600">{value.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </main>
    </div>
  )
}

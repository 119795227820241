import CheckoutForm from "../../components/website/Checkout.component"

export default function CheckoutPage() {

    return (
        <>
        <div className="mt-8">
        <CheckoutForm />
        </div>
        </>
    )
    
}
import React, { useState } from 'react';
import authenticationService from '../services/authorization-services/auth.service';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export default function PasswordResetForm() {
  const { reset_password_token } = useParams();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    console.log('Form submitted');

    if (password !== passwordConfirmation) {
      console.log('Passwords do not match');
      toast.error('Password and password confirmation do not match.');
      setIsLoading(false);
      return;
    }

    try {
      const user = {
        reset_password_token,
        password,
        password_confirmation: passwordConfirmation,
      };

      console.log('User data prepared:', user);

      const resetResponse = await authenticationService.resetPassword(user);

      console.log('Reset Response:', resetResponse); // Log the response from the backend

      if (resetResponse.message === 'Password has been reset.') {
        console.log('Password reset successful, attempting auto-login');

        // Automatically log in the user after password reset
        const loginResponse = await authenticationService.login({
          email: resetResponse.email, // Ensure you have the email from the reset response
          password,
        });

        console.log('Login Response:', loginResponse);

        if (loginResponse) {
          console.log('Auto-login successful, navigating to home page');
          setIsLoading(false);
          toast.success('Password reset successful. You are now logged in.');
          navigate('/home'); // Adjust the path to your home page
        } else {
          console.log('Auto-login failed');
          throw new Error('Auto-login failed.');
        }
      } else {
        console.log('Failed to reset password, status code:', resetResponse.status);
        throw new Error('Failed to reset password.');
      }
    } catch (error) {
      console.error('Error during password reset process:', error);
      toast.error('Failed to reset password.');
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen flex-1 items-center justify-center px-4 py-12 lg:px-8">
      <div className="w-full max-w-md px-2">
        <div className="bg-white py-8 px-4 shadow-md border border-gray-200 rounded-2xl">
          <div className="flex flex-col items-center">
            <img src="/suninn_logo.png" alt="Logo" className="w-16 mb-4" />
            <div className="text-center mb-10">
              <h2 className="text-3xl font-bold text-gray-900">Set Your New Password</h2>
              <p className="text-gray-600 mt-2">Please enter your new password below to reset your existing password.</p>
            </div>
            <form onSubmit={handleSubmit} className="bg-white p-2 rounded-lg w-full max-w-sm">
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">New Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="passwordConfirmation" className="block text-sm font-medium text-gray-700">Confirm Password</label>
                <input
                  type="password"
                  id="passwordConfirmation"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full mt-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isLoading ? 'Resetting...' : 'Reset Password'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import accountService from "../../services/accounts.service";
import toast from 'react-hot-toast';
import { LoadingSpinnerOverlay } from '../LoadingSpinnerOverlay';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#4A5568",
      fontFamily: 'Inter, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      '::placeholder': {
        color: '#A0AEC0',
      },
    },
    invalid: {
      color: "#E53E3E",
    },
  },
};

const BillingInfo = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [subscriptionEndDate, setSubscriptionEndDate] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [accountType, setAccountType] = useState('');
  const [isInTrial, setIsInTrial] = useState(false);

  useEffect(() => {
    if (user) {
      accountService.getSetupIntent().then(res => {
        const { subscription_end_date, client_secret, subscription_status, account_type, trial_end_date } = res.data;
        setClientSecret(client_secret);
        setSubscriptionEndDate(subscription_end_date);
        setSubscriptionStatus(subscription_status);
        setAccountType(account_type);
        setIsInTrial(trial_end_date ? true : false); 
        setIsLoading(false);
      }).catch(error => {
        console.error('Failed to fetch setup intent:', error);
        toast.error("Failed to load subscription details.");
        setIsLoading(false);
      });
    }
  }, [user]);

  const handleUnsubscribe = async () => {
    accountService.unsubscribeUser().then(() => {
      toast.success('Subscription cancellation requested.');
      setSubscriptionStatus('pending_cancellation');
    }).catch(error => {
      toast.error(`Error requesting cancellation: ${error.message}`);
    });
  };
  
  const handleReinstate = async () => {
    accountService.reinstateUser().then(() => {
      toast.success('Subscription reinstated successfully!');
      setSubscriptionStatus('active');
    }).catch(error => {
      toast.error(`Failed to reinstate subscription: ${error.message}`);
    });
  };
  
  if (isLoading) {
    return <LoadingSpinnerOverlay />;
  }

  return (
    <>
      {clientSecret && accountType === 'sales_partner' ? (
        <div className="p-7 md:col-span-2 space-y-6 bg-white border rounded-lg">
          <h3 className="text-lg font-semibold text-gray-900">Subscription Details</h3>
          <div className="space-y-4">
            <div>
              <p className="text-md text-gray-700">Subscription Status:
                <span className={`font-semibold ${subscriptionStatus === 'pending_cancellation' ? 'text-orange-500' : subscriptionStatus === 'active' ? 'text-green-500' : 'text-red-500'}`}>
                  {isInTrial && subscriptionStatus !== 'pending_cancellation' ? ' Free Trial' : " " + subscriptionStatus}
                </span>
              </p>
            </div>
            <div>
              <p className="text-md text-gray-700">
                {isInTrial ? `Trial ends on: ${subscriptionEndDate}` : `Next billing date: ${subscriptionEndDate}`}
              </p>
            </div>

            {/* FOR NOW TILL I CAN SOLVE THE STRIPE COOKIES ERROR LETS JUST HIDE THE CARD ELEMENT */}
            <div className="border-t border-gray-200 pt-4">
              <h3 className="text-md font-semibold text-gray-900 mb-2">Manage Your Payment Method:</h3>
              <div id="card-element" className="p-4 border border-gray-300 rounded-md">
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </div>
              <p className="text-sm text-gray-600 mt-2">We do not store your payment information on our servers. Payments are securely processed by Stripe.</p>
            </div>

            <div className="flex flex-col sm:flex-row justify-end gap-4 mt-6">
              {subscriptionStatus !== 'pending_cancellation' && (
                <button onClick={handleUnsubscribe} className="w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  {isInTrial ? 'Cancel Trial' : 'Cancel Subscription'}
                </button>
              )}
              {subscriptionStatus === 'pending_cancellation' && (
                <button onClick={handleReinstate} className="w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
  
                  {isInTrial ? 'Reinstate Trial' : 'Reinstate Subscription'}
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="md:col-span-2 mt-10 space-y-6 bg-white p-8 shadow-lg rounded-lg">
          <h3 className="text-lg font-semibold text-gray-900">No Active Subscription</h3>
          <p className="text-md text-gray-500">
            You are currently signed up as a SUNIN Referral Partner, so your monthly subscription is free! Upgrade your subscription for $129 per month and gain access to higher commissions as a SUNIN Sales Partner.
          </p>
          <a href="/upgradesubscription">
            <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Upgrade for $129 per month
            </button>
          </a>
        </div>
      )}
    </>
  );
}

export default BillingInfo;


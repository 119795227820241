import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import authenticationService from '../services/authorization-services/auth.service';
import Cookies from "universal-cookie";
import 'react-toastify/dist/ReactToastify.css';


function Login() {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const bearerToken = cookies.get("authorization");
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(''); 
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        if (bearerToken) {
            navigate('/home'); 
        }
    }, [bearerToken, navigate]);

    const authenticateUser = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        
        try {
            const data = { email, password };
            await authenticationService.login(data);
        } catch (error) {
            console.error("Login Error:", error);
            const message = error?.response?.data?.message || "Email or Password are incorrect.";
            setErrorMessage(message);
        }
    };

    return (
        <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 lg:px-8">
            <div className="w-full max-w-md px-2">
                <div className="bg-white py-8 px-4 shadow-md border border-gray-200 rounded-2xl">
                    <div className="flex flex-col items-center">
                        <img src="/suninn_logo.png" alt="Logo" className="w-16" />
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                            MySunin
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Not a member?{' '}
                            <Link to="/signup" className="font-semibold text-blue-600 hover:text-blue-500">
                                Sign Up
                            </Link>
                        </p>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={authenticateUser}>
                        {errorMessage && (
                            <div className="text-center text-sm font-medium text-red-600">
                                {errorMessage}
                            </div>
                        )}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-900">Email address</label>
                            <input
                                required
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                placeholder="Your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-900">Password</label>
                            <input
                                required
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                placeholder="Your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="text-sm">
                                <Link to="/request-password-reset" className="font-medium text-blue-600 hover:text-blue-500"> Forgot your password? </Link>
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                Sign in
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;

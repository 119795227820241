import React, { useState, useEffect } from 'react';

function scrollToNextSection() {
  document.getElementById('nextSection').scrollIntoView({ behavior: 'smooth' });
}

export default function IndexHero() {
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setAnimationStarted(true), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <style>
        {`
        @keyframes fade-in {
          from { opacity: 0; transform: scale(0.95); }
          to { opacity: 1; transform: scale(1); }
        }

        .animate-fade-in {
          animation: fade-in 1.5s ease-out forwards;
        }

        .slide-in {
          opacity: 0;
          transform: translateX(50px);
          animation: slide-in 0.8s forwards;
        }

        @keyframes slide-in {
          100% { opacity: 1; transform: translateX(0); }
        }
        `}
      </style>
      <div className="pt-14 relative overflow-hidden" style={{ minHeight: 'calc(92vh)' }}>
        <img
          src="/images/extra_compressed_landing.jpg"
          alt="Sunin Energy"
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
        <div className="absolute inset-0 -z-10 bg-black opacity-60" aria-hidden="true"></div>
        <div className="flex flex-col items-center justify-end h-full text-center pb-20"> {/* Adjusted for lower positioning */}
          <div className={`${animationStarted ? 'animate-fade-in' : ''} w-full`}>
            <img
              src="/suninn_logo.png"
              alt="Sunin Logo"
              className="mx-auto w-60 mt-12 mb-7 h-auto"
            />
            <h1 className="mt-4 text-4xl sm:text-5xl font-bold text-white mb-12 ">
              Empowering a Sustainable Future.
            </h1>
            <section className="hidden sm:block text-xl sm:text-2xl mb-8 text-white max-w-3xl mx-auto pt-5">
              <p>
                Sunin Energy's commercial solar fulfillment and sales platform stands as the industry leader, 
                empowering small to medium businesses through a member network that fosters collaboration, 
                education, and shared success across American communities.
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

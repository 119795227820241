import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import authenticationService from '../services/authorization-services/auth.service';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const [verificationStatus, setVerificationStatus] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      authenticationService.verifyEmail(token)
        .then(data => {
          setVerificationStatus({ message: data.message || 'Email verification successful!', type: 'success' });
        })
        .catch(error => {
          console.error('Verification error:', error);
          setVerificationStatus({ message: 'Failed to verify email. Please try again.', type: 'error' });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
      setVerificationStatus({ message: 'No verification token found.', type: 'error' });
    }
  }, [searchParams]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-xl font-semibold mb-4">Email Verification</h1>
      {loading ? (
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      ) : (
        <div className={`w-full max-w-md p-4 text-white ${verificationStatus.type === 'success' ? 'bg-green-500' : 'bg-red-500'} rounded-lg shadow-md`}>
          {verificationStatus.message}
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;

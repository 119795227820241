import CheckoutForm from "../components/website/Checkout.component";
import AppHeader from "../layout/AppHeader";

export default function UpgradeSubscription() {
    return (
        <>
            <AppHeader inner_content={
                <div className="mt-8">
                    <CheckoutForm />
                </div>
            }>
            </AppHeader>
        </>
    );
}
import React, { useReducer, useEffect, useState, Fragment } from 'react';
import projectService from '../../services/projects.service';
import authService from '../../services/authorization-services/auth.service';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import ProposalTicketCard from './ProposalTicketCard.component';

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_STATE':
      return { ...action.state };
    case 'RESET':
      return { ...action.initialState };
    default:
      return state;
  }
};

const proposalStatus = [
  'Proposal requested and pending review',
  'Proposal accepted and assigned to engineer',
  'Proposal created and attached to project',
  'Proposal request rejected',
  'Revision requested and pending review',
  'Revision request accepted and assigned to engineer',
  'Revision completed and attached to project',
  'Revision rejected',
];

export default function ProposalManagement({ project }) {
  const initialFormState = {
    details: '',
    actionTakenBy: '',
    revisionDetails: '',
    revisionType: '',
  };

  const [formState, dispatch] = useReducer(formReducer, initialFormState);
  const [proposalTicketSubmitted, setProposalTicketSubmitted] = useState(false);
  const [proposalTickets, setProposalTickets] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showRevisionForm, setShowRevisionForm] = useState(false);
  const [detailsValid, setDetailsValid] = useState(true);
  const [actionTakenByValid, setActionTakenByValid] = useState(true);
  const [missingItems, setMissingItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await authService.getUserProfile();
        dispatch({ type: 'SET_FIELD', field: 'actionTakenBy', value: `${userProfile.first_name} ${userProfile.last_name}` });
      } catch (error) {
        console.error('Error fetching user profile:', error);
        toast.error('Failed to fetch user profile.');
      }
    };

    if (project.id) {
      fetchProposalTickets(project.id);
    }

    fetchUserProfile();
  }, [project.id]);

  useEffect(() => {
    const savedFormState = localStorage.getItem('formState');
    if (savedFormState) {
      dispatch({ type: 'SET_STATE', state: JSON.parse(savedFormState) });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('formState', JSON.stringify(formState));
  }, [formState]);

  const validateProject = () => {
    const requiredFields = [
      'utility_company',
      'meter_number',
      'number_of_meters',
      'main_panel_amperage',
      'power_bill_received',
      'january_kilowatt_amount',
      'february_kilowatt_amount',
      'march_kilowatt_amount',
      'april_kilowatt_amount',
      'may_kilowatt_amount',
      'june_kilowatt_amount',
      'july_kilowatt_amount',
      'august_kilowatt_amount',
      'september_kilowatt_amount',
      'october_kilowatt_amount',
      'november_kilowatt_amount',
      'december_kilowatt_amount',
      'annual_kilowatt_amount',
      'average_monthly_power_bill_cost',
      'type_of_roof',
      'age_of_roof',
      'address_1',
      'city',
      'state',
      'zip',
      'company_name',
      'utility_account_holder',
      'utility_account_number',
      'shade_interference',
    ];

    const missingFields = requiredFields.filter(field => !project[field]);
    return missingFields;
  };

  const handleSubmitProposalTicket = async () => {
    const { details } = formState;
    const isActionTakenByValid = !!formState.actionTakenBy;
    setActionTakenByValid(isActionTakenByValid);

    const missingFields = validateProject();
    if (missingFields.length > 0 || !isActionTakenByValid) {
      setMissingItems(missingFields);
      setIsModalOpen(true);
      return;
    }

    const proposalTicketData = {
      project_id: project.id,
      title: 'Proposal Management Ticket',
      details: details,
      company_name: project.company_name,
      company_address: `${project.address_1}, ${project.address_2 || ''} ${project.city}, ${project.state} ${project.zip}`,
      status: proposalStatus[0], // Initial status
    };

    setLoading(true);
    try {
      await projectService.newProposalTicket(proposalTicketData);
      toast.success('Proposal ticket submitted successfully!');
      setProposalTicketSubmitted(true);
      fetchProposalTickets(project.id);
      dispatch({ type: 'RESET', initialState: initialFormState });
      setShowForm(false);
    } catch (error) {
      console.error('Error submitting proposal ticket:', error);
      toast.error('Failed to submit proposal ticket.');
    } finally {
      setLoading(false);
    }
  };

  const handleRequestRevision = async () => {
    const { revisionDetails, revisionType } = formState;
    if (!revisionDetails || !revisionType) {
      toast.error('Please provide details and select a revision type.');
      return;
    }

    let newStatus;
    if (revisionType === 'Request a separate proposal design') {
      newStatus = proposalStatus[4]; // 'Proposal requested and pending review'
    } else if (revisionType === 'Request a revision to the original proposal') {
      newStatus = proposalStatus[4]; // 'Revision requested and pending review'
    }

    const revisionData = {
      status: newStatus,
      details: revisionDetails,
    };

    setLoading(true);
    try {
      await projectService.updateProposalTicket(project.id, selectedTicketId, revisionData);
      toast.success('Revision requested successfully!');
      fetchProposalTickets(project.id);
      setShowRevisionForm(false);
      setSelectedTicketId(null);
      dispatch({ type: 'RESET', initialState: initialFormState });
    } catch (error) {
      console.error('Error requesting revision:', error);
      toast.error('Failed to request revision.');
    } finally {
      setLoading(false);
    }
  };

  const fetchProposalTickets = async (projectId) => {
    setLoading(true);
    try {
      const response = await projectService.getProposalTickets(projectId);
      const ticketsWithHistories = await Promise.all(response.data.map(async (ticket) => {
        const historyResponse = await projectService.getProposalTicketHistories(projectId, ticket.id);
        console.log(historyResponse);
        return { ...ticket, proposal_ticket_histories: historyResponse.data };
      }));
      setProposalTickets(ticketsWithHistories);
    } catch (error) {
      console.error('Error fetching proposal tickets:', error);
      toast.error('Failed to fetch proposal tickets.');
    }
    setLoading(false);
  };

  const formatDateTime = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeForm = () => {
    setShowForm(false);
    dispatch({ type: 'RESET', initialState: initialFormState });
  };

  const closeRevisionForm = () => {
    setShowRevisionForm(false);
    setSelectedTicketId(null);
    dispatch({ type: 'RESET', initialState: initialFormState });
  };

  const openRevisionForm = (ticketId) => {
    setShowRevisionForm(true);
    setSelectedTicketId(ticketId);
  };

  const existingTicket = proposalTickets.length > 0;

  return (
    <div className="flex justify-center mt-5">
      <div className="w-full max-w-7xl">

        {/* Proposal Links Card */}
        {proposalTickets.length > 0 ? (
          proposalTickets.map((ticket, index) => (
            <div key={index} className="rounded-lg bg-white px-4 py-4 shadow-md border border-gray-100 mb-10">
              <h2 className="text-2xl font-semibold text-gray-800 py-2 mb-4">Proposal Links</h2>
              {ticket.proposal_links.length > 0 ? (
                <ul className="list-disc list-inside space-y-2">
                  {ticket.proposal_links.map((link, linkIndex) => (
                    <li key={linkIndex} className="flex items-center space-x-3 p-2 bg-gray-50 hover:bg-gray-100 rounded-md transition">
                      <svg className="h-6 w-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 11.172a4 4 0 010 5.656L12 18.656l-1.828-1.828a4 4 0 010-5.656L12 9.344l1.828 1.828zm0-6.344L12 4.344 10.172 6.172a6 6 0 000 8.656L12 16.656l1.828-1.828a6 6 0 000-8.656z" />
                      </svg>
                      <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline font-medium">
                        {link.title || `Proposal ${linkIndex + 1}`}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500">No proposals for this ticket.</p>
              )}
            </div>
          ))
        ) : (
          <div className="rounded-lg bg-white px-4 py-4 shadow-md border border-gray-100 mb-10">
            <h2 className="text-2xl font-semibold text-gray-800 py-2 mb-4">Proposal Links</h2>
            <div className="flex flex-col items-center justify-center h-64 bg-gray-50 rounded-lg border-2 border-dashed border-gray-300">
              <div className="text-center">
                <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12h18m-9-9v18" />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-gray-900">No proposals yet</h3>
                <p className="mt-1 text-sm text-gray-500">Request a proposal to get started, or await patiently if you already have.</p>
              </div>
            </div>
          </div>
        )}

        {/* Request Proposal Card */}
        <div className="rounded-lg bg-white px-6 py-6 shadow-lg border border-gray-100 mb-10">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold text-gray-800">Proposal Ticket</h2>
            {!showForm && !project.proposal_url && (
              <button
                type="button"
                className={`mt-2 sm:mt-0 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm ${existingTicket ? 'text-gray-500 bg-gray-200 cursor-not-allowed' : 'text-white bg-blue-500 hover:bg-blue-700 focus:outline-none'}`}
                onClick={() => !existingTicket && setShowForm(true)}
                disabled={existingTicket}
              >
                {existingTicket ? 'Proposal already exists' : 'Request Proposal'}
              </button>
            )}
          </div>


          {showForm && (
            <div className="mt-4">
              <div>
                <ProjectDetails project={project} />
                <div className="mb-4">
                  <label className="text-gray-700">Details:</label>
                  <textarea
                    className="block w-full rounded-md border border-gray-300 shadow-sm p-3"
                    rows="4"
                    value={formState.details}
                    onChange={e => {
                      const value = e.target.value;
                      dispatch({ type: 'SET_FIELD', field: 'details', value });
                      setDetailsValid(value.length > 0);
                    }}
                  ></textarea>
                  {!detailsValid && <p className="text-red-500">Details are required.</p>}
                </div>
                <div className="flex justify-between">
                  <button
                    type="button"
                    className="mt-2 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    onClick={closeForm}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="mt-2 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleSubmitProposalTicket}
                    disabled={loading}
                  >
                    {loading ? 'Submitting...' : 'Submit Proposal Ticket'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Proposal Tickets Card */}
        {proposalTickets.length > 0 ? (
          <div className="mt-4">
            <div className="space-y-6">
              {proposalTickets.map((proposalTicket, index) => (
                <ProposalTicketCard
                  key={index}
                  proposalTicket={proposalTicket}
                  formatDateTime={formatDateTime}
                  openRevisionForm={openRevisionForm}
                  showRevisionForm={showRevisionForm}
                  selectedTicketId={selectedTicketId}
                  setShowRevisionForm={setShowRevisionForm}
                  handleRequestRevision={handleRequestRevision}
                  dispatch={dispatch}
                  formState={formState}
                  setRevisionDetails={(value) => dispatch({ type: 'SET_FIELD', field: 'revisionDetails', value })}
                  setRevisionType={(value) => dispatch({ type: 'SET_FIELD', field: 'revisionType', value })}
                  revisionDetails={formState.revisionDetails}
                  revisionType={formState.revisionType}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="mt-12 flex flex-col items-center justify-center h-64 bg-gray-50 rounded-lg border-2 border-dashed border-gray-300">
            <div className="text-center">
              <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12h18m-9-9v18" />
              </svg>
              <h3 className="mt-2 text-lg font-medium text-gray-900">No Proposal Tickets</h3>
              <p className="mt-1 text-md text-gray-600">Start by submitting a request for a proposal to get started.</p>
            </div>
          </div>
        )}

        <Transition show={isModalOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Missing Required Fields
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Please complete the following required fields before submitting the proposal ticket:
                          </p>
                          <ul className="list-disc list-inside text-sm text-gray-500 mt-2">
                            {missingItems.map((item, index) => (
                              <li key={index}>{item.replace(/_/g, ' ')}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={closeModal}
                      >
                        Okay
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}

function ProjectDetails({ project }) {
  return (
    <>
      <div className="mb-4">
        <p className="text-gray-700"><strong>Project ID:</strong> {project.id}</p>
      </div>
      <div className="mb-4">
        <p className="text-gray-700"><strong>Company Name:</strong> {project.company_name}</p>
      </div>
      <div className="mb-4">
        <p className="text-gray-700"><strong>Street Address:</strong> {project.address_1} {project.address_2} {project.city}, {project.state} {project.zip}</p>
      </div>
    </>
  );
}


import React, { useState } from 'react';
import AllUsersComponent from '../../components/admin/AllUsers.component';
import AllAccounts from '../../components/admin/AllAccounts.component'
import AllProjectsComponent from '../../components/admin/AllProjects.component';
import AdminProjectStages from '../../components/admin/ProjectStages.components';

const AdminUsers = () => {
    const [activeTab, setActiveTab] = useState('All Users');
  
    return (
      <div className="flex justify-center items-center m-5 ">
        <div className="bg-white border shadow-xl rounded-lg w-full max-w-7xl p-6">
          <ul className="flex flex-col sm:flex-row justify-between border-b">
            <li
              className={`p-4 cursor-pointer ${activeTab === 'All Users' ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => setActiveTab('All Users')}
            >
              All Users
            </li>
            <li
              className={`p-4 cursor-pointer ${activeTab === 'All Accounts' ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => setActiveTab('All Accounts')}
            >
              All Accounts
            </li>
            <li
              className={`p-4 cursor-pointer ${activeTab === 'All Projects' ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => setActiveTab('All Projects')}
            >
              All Projects
            </li>
            <li
              className={`p-4 cursor-pointer ${activeTab === 'Ticketing System' ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => setActiveTab('Ticketing System')}
            >
              Project Stages
            </li>
          </ul>
          <div className="h-full">
            {activeTab === 'All Users' && (
              <div className="text-gray-800 text-lg h-full">
                <AllUsersComponent />
              </div>
            )}
            {activeTab === 'All Accounts' && (
              <div className="text-gray-800 text-lg">
                <AllAccounts/>
              </div>
            )}
            {activeTab === 'All Projects' && (
                <div className="text-gray-800 text-lg">
                    <AllProjectsComponent/>
                </div>)}
            {activeTab === 'Ticketing System' && (
                <div className="text-gray-800 text-lg">
                    <AdminProjectStages/>
                </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  export default AdminUsers;

import React from 'react';
import { LockClosedIcon } from '@heroicons/react/24/outline';

export default function AdminDashboard() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="/suninn_logo.png"
            alt="Suninn"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Admin Dashboard
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Welcome to the C-Suite admin dashboard
          </p>
        </div>
        <div className="rounded-lg shadow-lg overflow-hidden bg-white max-w-md mx-auto">
          <div className="p-6 sm:p-8">
            <div className="text-center">
              <LockClosedIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-2xl font-semibold text-gray-900">Coming Soon</h3>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, ChevronDownIcon, XMarkIcon, ExclamationCircleIcon, ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/react/24/solid';

const mockNotifications = [
  {
    id: 1,
    title: 'Welcome to the platform!',
    message: 'We are glad to have you on board.',
    timestamp: '2023-05-01T10:30:00Z',
    importance: 'high',
    show: true,
  },
  {
    id: 2,
    title: 'Account Verification',
    message: 'Please verify your email to complete your registration.',
    timestamp: '2023-05-02T14:00:00Z',
    importance: 'medium',
    show: true,
  },
  {
    id: 3,
    title: 'Profile Update',
    message: 'Your profile has been successfully updated.',
    timestamp: '2023-05-03T08:45:00Z',
    importance: 'low',
    show: true,
  },
  {
    id: 4,
    title: 'New Message',
    message: 'You have received a new message from John Doe.',
    timestamp: '2023-05-04T16:20:00Z',
    importance: 'medium',
    show: true,
  },
  {
    id: 5,
    title: 'Maintenance Alert',
    message: 'Scheduled maintenance will occur on May 10th.',
    timestamp: '2023-05-05T09:00:00Z',
    importance: 'high',
    show: true,
  },
  {
    id: 6,
    title: 'New Feature Released',
    message: 'Check out the new features in your profile settings.',
    timestamp: '2023-05-06T11:30:00Z',
    importance: 'low',
    show: true,
  },
  {
    id: 7,
    title: 'Security Notice',
    message: 'Please update your password for better security.',
    timestamp: '2023-05-07T14:45:00Z',
    importance: 'high',
    show: true,
  },
  {
    id: 8,
    title: 'Meeting Reminder',
    message: 'Don’t forget about the team meeting tomorrow at 10 AM.',
    timestamp: '2023-05-08T16:00:00Z',
    importance: 'medium',
    show: true,
  },
  {
    id: 9,
    title: 'Survey Invitation',
    message: 'We value your feedback! Please take a moment to fill out our survey.',
    timestamp: '2023-05-09T12:00:00Z',
    importance: 'low',
    show: true,
  },
  {
    id: 10,
    title: 'System Update',
    message: 'A new system update will be installed tonight.',
    timestamp: '2023-05-10T19:00:00Z',
    importance: 'medium',
    show: true,
  },
  {
    id: 1,
    title: 'Welcome to the platform!',
    message: 'We are glad to have you on board.',
    timestamp: '2023-05-01T10:30:00Z',
    importance: 'high',
    show: true,
  },
  {
    id: 2,
    title: 'Account Verification',
    message: 'Please verify your email to complete your registration.',
    timestamp: '2023-05-02T14:00:00Z',
    importance: 'medium',
    show: true,
  },
  {
    id: 3,
    title: 'Profile Update',
    message: 'Your profile has been successfully updated.',
    timestamp: '2023-05-03T08:45:00Z',
    importance: 'low',
    show: true,
  },
  {
    id: 4,
    title: 'New Message',
    message: 'You have received a new message from John Doe.',
    timestamp: '2023-05-04T16:20:00Z',
    importance: 'medium',
    show: true,
  },
  {
    id: 5,
    title: 'Maintenance Alert',
    message: 'Scheduled maintenance will occur on May 10th.',
    timestamp: '2023-05-05T09:00:00Z',
    importance: 'high',
    show: true,
  },
  {
    id: 6,
    title: 'New Feature Released',
    message: 'Check out the new features in your profile settings.',
    timestamp: '2023-05-06T11:30:00Z',
    importance: 'low',
    show: true,
  },
  {
    id: 7,
    title: 'Security Notice',
    message: 'Please update your password for better security.',
    timestamp: '2023-05-07T14:45:00Z',
    importance: 'high',
    show: true,
  },
  {
    id: 8,
    title: 'Meeting Reminder',
    message: 'Don’t forget about the team meeting tomorrow at 10 AM.',
    timestamp: '2023-05-08T16:00:00Z',
    importance: 'medium',
    show: true,
  },
  {
    id: 9,
    title: 'Survey Invitation',
    message: 'We value your feedback! Please take a moment to fill out our survey.',
    timestamp: '2023-05-09T12:00:00Z',
    importance: 'low',
    show: true,
  },
  {
    id: 10,
    title: 'System Update',
    message: 'A new system update will be installed tonight.',
    timestamp: '2023-05-10T19:00:00Z',
    importance: 'medium',
    show: true,
  },
];

const Breadcrumbs = () => (
  <nav className="text-sm font-medium mb-4 pl-4 sm:pl-0">
    <ol className="list-reset flex">
      <li>
        <a href="/" className="text-gray-600 hover:text-gray-900">
          Home
        </a>
      </li>
      <li>
        <span className="mx-2 text-gray-600">/</span>
      </li>
      <li className="text-gray-900">Notifications</li>
    </ol>
  </nav>
);

const urgencyIcons = {
  high: <ExclamationCircleIcon className="w-5 h-5 text-red-600" />,
  medium: <ExclamationTriangleIcon className="w-5 h-5 text-yellow-600" />,
  low: <CheckCircleIcon className="w-5 h-5 text-green-600" />,
};

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setNotifications(mockNotifications);
    }, 1000);
  }, []);

  const hideNotification = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id ? { ...notification, show: false } : notification
      )
    );
  };

  return (
    <div className="mx-auto max-w-3xl my-5 pb-12 sm:px-0 sm:py-0">
      <Breadcrumbs />
      <div className="sm:bg-white sm:shadow-md sm:rounded-lg sm:border sm:border-gray-200 sm:max-h-[600px] overflow-y-auto">
        {notifications.length === 0 ? (
          <p>Loading notifications...</p>
        ) : (
          notifications.filter(notification => notification.show).map((notification) => (
            <div key={notification.id} className="border-b border-gray-200 py-4 flex items-start px-4">
              <div className="flex-shrink-0">
                {urgencyIcons[notification.importance]}
              </div>
              <div className="ml-3  w-full">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <div className="flex justify-between items-center">
                        <Disclosure.Button className="flex items-center justify-between w-full text-left text-gray-900">
                          <div className="flex items-center">
                            <span className="ml-2 text-lg font-medium">{notification.title}</span>
                          </div>
                          <div className="flex items-center">
                            {open ? (
                              <ChevronUpIcon className="w-5 h-5 text-gray-500" />
                            ) : (
                              <ChevronDownIcon className="w-5 h-5 text-gray-500" />
                            )}
                          </div>
                        </Disclosure.Button>
                      </div>
                      <Disclosure.Panel className="mt-2">
                        <p className={`text-gray-600 ${open ? '' : 'truncate'}`}>{notification.message}</p>
                        <p className={`text-gray-400 text-sm ${open ? '' : 'truncate'}`}>
                          {new Date(notification.timestamp).toLocaleString()}
                        </p>
                        <div className="flex justify-end mt-4">
                          <button
                            onClick={() => hideNotification(notification.id)}
                            className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-red-600 bg-white border border-red-600 rounded-md hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
                          >
                            <XMarkIcon className="w-4 h-4 mr-1" />
                            Hide
                          </button>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Notifications;

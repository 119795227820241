const features = [
    {
      name: 'Design Excellence.',
      description:
        'SUNIN Energy specializes in precision solar design, empowering sellers with solutions that seamlessly integrate into properties. Our expert designers maximize energy production and aesthetic appeal, ensuring a smooth transition to renewable energy.',
    },
    {
      name: 'Engineering Expertise.',
      description:
        "SUNIN Energy boasts the industry's top engineering team, dedicated to providing sellers with the tools they need for success. Our engineers bring innovation and expertise to every aspect of renewable energy solutions, ensuring top-tier performance and reliability.",
    },
    {
      name: 'Tailored Financing.',
      description:
        "SUNIN Energy unlocks custom financing options tailored to sellers' specific needs. Our financial experts work closely with sellers to ensure that their solar projects are not only sustainable for the planet but also financially viable for their businesses.",
    },
    {
      name: 'Project Management',
      description:
        "SUNIN Energy simplifies the complexities of solar projects for sellers. Our comprehensive approach, from engineering and financing to design and installation, guarantees a hassle-free experience. We manage every detail to ensure sellers' projects are on track and on budget.",
    },
    {
      name: 'Professional Installation',
      description:
        "Sellers can trust in SUNIN Energy's network of verified turnkey installers with a proven track record of quality work. Our installers are dedicated to ensuring the seamless deployment of solar projects, backed by our unwavering commitment to excellence.",
    },
    {
      name: 'One Consolidated Platform.',
      description:
        'SUNIN Energy serves as a comprehensive platform for solar sellers. We offer end-to-end support, simplifying the solar selling process. From engineering to financing, design to installation, our unified platform guarantees a streamlined experience for sellers.',
    },
  ]
  
  export default function Example() {
    return (
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">All-in-one platform</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              SUNIN Energy is all about empowering local community through renewable energy. So,
              we designed a member based solar fullfilment platform, to provide solar energy to the small and medium businesses that need it most!
            </p>
          </div>
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt className="font-semibold text-gray-900">{feature.name}</dt>
                <dd className="mt-1 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    )
  }
  
import React, { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import adminService from "../../services/admin.service";

export default function AllAccounts() {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const accountsPerPage = 8;
  const [sortField, setSortField] = useState('');

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const handleSortChange = (e) => {
    setSortField(e.target.value);
  };

  const sortedAccounts = [...accounts].sort((a, b) => {
    if (sortField) {
      if (a[sortField] < b[sortField]) return -1;
      if (a[sortField] > b[sortField]) return 1;
    }
    return 0;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchAccounts = async () => {
      setLoading(true);
      const response = await adminService.getAllAccounts();
      if (response && response.data) {
        const sortedAccounts = response.data.sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        setAccounts(sortedAccounts);
      } else {
        console.error('Failed to fetch accounts, response or response.data is undefined');
      }
      setLoading(false);
    };
    fetchAccounts();
  }, []);

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
    setCurrentPage(1); 
  };

  const filteredAccounts = accounts.filter((account) => {
    if (currentFilter === 'All') return true;
    return account.account_status === currentFilter; 
  });
  
  const indexOfLastAccount = currentPage * accountsPerPage;
  const indexOfFirstAccount = indexOfLastAccount - accountsPerPage;
  const currentAccounts = filteredAccounts.slice(indexOfFirstAccount, indexOfLastAccount); 

  const totalPages = Math.ceil(filteredAccounts.length / accountsPerPage);

  const handlePreviousClick = () => {
    setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, totalPages));
  };

  const startResult = (currentPage - 1) * accountsPerPage + 1;
  const endResult = Math.min(startResult + accountsPerPage - 1, filteredAccounts.length);

  return (
    <div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-2">
        <nav className="sm:hidden mt-5" aria-label="Back">
          <Link to="/admin" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Admin Home
          </Link>
        </nav>
        <nav className="hidden sm:flex mt-5" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <Link to="/admin" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                    Admin Home
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <Link to="/admin/all-accounts" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  All Accounts
                </Link>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 mt-5">
        <div className="rounded-lg bg-white px-5 py-6 shadow-lg border border-gray-100 sm:px-6">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-xl font-semibold text-gray-900 my-4">All Accounts</h3>
          </div>
          <div className="flex min-h-full flex-col">
            <div className="px-0">
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 table-fixed">
                      <thead>
                        <tr>
                          <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Account ID</th>
                          <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Company</th>
                          <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Industry</th>
                          <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Status</th>
                          <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {currentAccounts.length > 0 ? (
                          currentAccounts.map((account) => (
                            <tr key={account.id} className="even:bg-gray-50">
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">{account.id}</td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate">{account.company_name}</td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">{account.industry}</td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
                                    account.account_status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                  }`}>
                                  {account.account_status}
                                </span>
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                <Link to={"/admin/accounts/" + account.id} className="text-blue-500 hover:text-blue-700">
                                  Manage
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">No Accounts Yet</td>
                          </tr>
                        )}
                        {currentAccounts.length < accountsPerPage && [...Array(accountsPerPage - currentAccounts.length)].map((_, i) => (
                          <tr key={`empty-${i}`} className="even:bg-gray-50">
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-md text-gray-500"></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <nav
                      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                      aria-label="Pagination"
                    >
                      <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                          Showing <span className="font-medium">{startResult}</span> to <span className="font-medium">{endResult}</span> of{' '}
                          <span className="font-medium">{filteredAccounts.length}</span> results
                        </p>
                      </div>
                      <div className="flex flex-1 justify-between sm:justify-end">
                        <button
                          onClick={handlePreviousClick}
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Previous
                        </button>
                        <button
                          onClick={handleNextClick}
                          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Next
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

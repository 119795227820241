import http from "../http-common";
import serviceUtil from "../utils/service-util";  // Ensure the import path is correct

class LeadService {
    async newLead(data) {
        return await http.post("/leads", { "lead": data });
    }

    async updateLead(data) {
        const id = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.patch(`/leads/${id}`, { "lead": data });
    }

    async getLeads() {
        return await http.get("/leads");
    }

    async getLead() {
        const leadId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.get(`/leads/${leadId}`);
    }

    async uploadLeadFiles(leadId, filesData) {
        try {
            const response = await http.post(`/leads/${leadId}/lead_files`, filesData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            return response;
        } catch (error) {
            console.error('Failed to upload lead files:', error);
            throw error;
        }
    }

    async getFiles() {
        const leadId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.get(`/leads/${leadId}/lead_files`);
    }
}

const leadService = new LeadService();
export default leadService;


import { useState } from 'react';

const useLoadingSpinner = () => {
  const [isLoading, setIsLoading] = useState(false);

  const withMinimumSpinTime = async (operation) => {
    setIsLoading(true);
    const minimumSpinTimePromise = new Promise(resolve => setTimeout(resolve, 1500));

    await Promise.all([
      operation(),
      minimumSpinTimePromise
    ]).catch(error => {
      console.error("An error occurred:", error);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return { isLoading, withMinimumSpinTime };
};



export const LoadingSpinnerOverlay = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <div style={{
      border: '4px solid #f3f3f3',
      borderTop: '4px solid #3498db',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      animation: 'spin 2s linear infinite',
    }} />
    <style>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

  
  export default useLoadingSpinner;
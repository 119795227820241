import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Tab } from '@headlessui/react';
import toast from 'react-hot-toast';

import adminService from "../../services/admin.service";
import PropertyInformationAccordion from '../../components/projects/PropertyInformation.component';
import UtilityInformationAccordion from '../../components/projects/UtilityInformation.component';
import ProjectContacts from '../../components/projects/ProjectContacts.component';
import ProjectPhotos from '../../components/projects/ProjectPhotos.component';
import ProjectLinks from '../../components/projects/ProjectLinks.component';
import ProposalManagement from '../../components/projects/ProposalTicketing.component';
import AdminProjectStages from '../../components/admin/ProjectStages.components';

const initialSteps = [
  { id: 'Project Initiation', name: 'Project Initiation' },
  { id: 'Presentation Ready', name: 'Presentation Ready' },
  { id: 'Financing and Documentation', name: 'Financing and Documentation' },
  { id: 'Site Survey and Finalization', name: 'Site Survey and Finalization' },
  { id: 'Project Completion', name: 'Project Completion' },
];

export default function ProjectPage({ projectId }) {
  const [project, setProject] = useState({});
  const [account, setAccount] = useState({});
  const [currentStep, setCurrentStep] = useState('Project Initiation');
  const tabListRef = useRef(null);
  const currentStepRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProject();
  }, []);

  useEffect(() => {
    if (project.project_stage) {
      setCurrentStep(project.project_stage);
    }
  }, [project]);

  useEffect(() => {
    if (currentStepRef.current) {
      scrollToCenter(currentStepRef.current);
    }
  }, [currentStep]);

  const scrollToCenter = (element) => {
    if (element && element.scrollIntoView) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  };

  const scrollTabs = (direction) => {
    if (tabListRef.current) {
      const scrollAmount = direction === 'left' ? -250 : 250;
      tabListRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const fetchProject = async () => {
    try {
      const response = await adminService.getProject(projectId);
      setProject(response.data);
      if (response.data.account_id) {
        fetchAccount(response.data.account_id);
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
      toast.error('Failed to fetch project data.');
    }
  };

  const fetchAccount = async (accountId) => {
    try {
      const response = await adminService.getAccount(accountId);
      setAccount(response.data.account);
    } catch (error) {
      console.error("Error fetching account data:", error);
      toast.error('Failed to fetch account data.');
    }
  };

  const getStatus = (stepId) => {
    const stepIndex = initialSteps.findIndex(step => step.id === stepId);
    const currentStepIndex = initialSteps.findIndex(step => step.id === currentStep);
    if (stepIndex < currentStepIndex) {
      return 'complete';
    } else if (stepIndex === currentStepIndex) {
      return 'current';
    } else {
      return 'upcoming';
    }
  };

  return (
    <>
      <div className='mb-5'>
        <div className="mx-4 sm:mx-auto max-w-7xl sm:px-6 lg:px-8 mt-5">
          <nav className="sm:hidden px-4" aria-label="Back">
            <Link to={`/admin/accounts/${account.id}`} className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
              <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              {account.company_name}
            </Link>
          </nav>
          <nav className="hidden sm:flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div className="flex">
                  <Link to="/admin" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                    Home
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <Link to="/admin/all-accounts" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    Accounts
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <a href={`/admin/accounts/${account.id}`} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    Account
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <a href={`/admin/projects/${project.id}`} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    Project
                  </a>
                </div>
              </li>
            </ol>
          </nav>

          <div className="flex justify-center">
            <div className="bg-white shadow border rounded-lg overflow-hidden sm:border-b sm:mt-4">
              <div className="sm:px-2  sm:py-0">
                <nav aria-label="Progress" className="overflow-x-auto bg-white rounded-sm border-t sm:border-t-0 border-b py-2 px-2 sm:px-0">
                  <ol className="flex" ref={tabListRef}>
                    {initialSteps.map((step, stepIdx) => {
                      const status = getStatus(step.id);
                      const bgColor =
                        status === 'complete'
                          ? 'bg-gray-700 text-white'
                          : status === 'current'
                          ? 'bg-green-700 text-white'
                          : 'bg-gray-100 text-gray-500 ';

                      const fillColor =
                        status === 'complete'
                          ? 'bg-gray-700'
                          : status === 'current'
                          ? 'bg-green-700'
                          : 'bg-gray-100';

                      return (
                        <li
                          key={step.name}
                          className={`relative flex-shrink-0 flex items-center justify-center px-2 py-1 sm:px-4 sm:py-2 text-xs font-medium rounded-lg ${bgColor}`}
                          style={{ width: '240px', minWidth: '240px', height: '30px'}}
                          ref={status === 'current' ? currentStepRef : null}
                        >
                          {status === 'complete' ? 'Completed' : step.name}

                          {stepIdx !== initialSteps.length - 1 && (
                            <div className="absolute right-0 top-0 h-full w-3 sm:w-5">
                              <svg
                                className="h-full w-full"
                                viewBox="0 0 22 80"
                                fill="none"
                                preserveAspectRatio="none"
                              >
                                <path
                                  d="M0 0L20 40L0 80H22V0H0Z"
                                  fill="white"
                                />
                                <path
                                  d="M0 -2L20 40L0 82"
                                  vectorEffect="non-scaling-stroke"
                                  stroke={fillColor}
                                  strokeLinejoin="round"
                                  fill={fillColor}
                                />
                              </svg>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ol>
                </nav>

                <div className="mt-6 px-4 pb-4 sm:border-gray-200">
                  <dl className="grid grid-cols-2 gap-y-6 sm:gap-x-8">
                    <div>
                      <dt className="text-sm font-medium text-gray-500">Company</dt>
                      <dd className="mt-1 text-sm text-gray-900">{project.company_name}</dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-500">Address</dt>
                      <dd className="mt-1 text-sm text-gray-900">{`${project.address_1} ${project.address_2} `}</dd>
                      <dd className="mt-1 text-sm text-gray-900">{`${project.city}, ${project.state} ${project.zip}`}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div> 

          <Tab.Group>
            <div className="w-full mt-2 sticky top-0 z-10 bg-white border-b ">
              <div className="relative flex items-center overflow-hidden py-1">
                <button
                  className="absolute left-1 bg-gray-200 p-1 rounded-full hover:bg-gray-400 z-20"
                  onClick={() => scrollTabs('left')}
                >
                  <ChevronLeftIcon className="h-6 w-6 text-gray-500" />
                </button>
                <div className="flex-1 mx-12 overflow-x-auto" ref={tabListRef}>
                  <Tab.List className="flex space-x-2 rounded-xl p-1.5">
                    {[
                      'Site Info',
                      'Utility Info',
                      'Project Contacts',
                      'Project Links',
                      'Assets',
                      'Proposal',
                      'Project Stages'
                    ].map((tab, index) => (
                      <Tab
                        key={tab}
                        className={({ selected }) =>
                          selected
                            ? ' w-40 flex-shrink-0 py-2.5 text-center text-sm leading-5 font-medium text-white bg-blue-700 rounded-lg'
                            : ' w-40 flex-shrink-0 py-2.5 text-center text-sm leading-5 font-medium text-gray-900 border border-gray-300 bg-white hover:bg-blue-500 hover:text-white rounded-lg'
                        }
                        // ref={index === 0 ? currentTabRef : null}
                      >
                        {tab}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
                <button
                  className="absolute right-1 bg-gray-200 p-1 rounded-full hover:bg-gray-400 z-20"
                  onClick={() => scrollTabs('right')}
                >
                  <ChevronRightIcon className="h-6 w-6 text-gray-500" />
                </button>
              </div>
            </div>
            <div className="flex min-h-full flex-col">
              <div className="mx-auto flex w-full max-w-7xl items-start">
                <main className="flex-1">
                  <div className="mx-auto mt-2">
                    <Tab.Panels className="mt-2">
                      <Tab.Panel>
                        <PropertyInformationAccordion project={project} fetchProject={fetchProject} />
                      </Tab.Panel>
                      <Tab.Panel>
                        <UtilityInformationAccordion project={project} fetchProject={fetchProject} />
                      </Tab.Panel>
                      <Tab.Panel>
                        <ProjectContacts project={project} fetchProject={fetchProject} />
                      </Tab.Panel>
                      <Tab.Panel>
                        <ProjectLinks projectId={project.id} />
                      </Tab.Panel>
                      <Tab.Panel>
                        <ProjectPhotos project={project} fetchProject={fetchProject} />
                      </Tab.Panel>
                      <Tab.Panel>
                        <ProposalManagement project={project} fetchProject={fetchProject}/>
                      </Tab.Panel>
                      <Tab.Panel>
                        <AdminProjectStages projectId={project.id} fetchProject={fetchProject}/>
                      </Tab.Panel>
                    </Tab.Panels>
                  </div>
                </main>
              </div>
            </div>
          </Tab.Group>
        </div>
      </div>
    </>
  );
}

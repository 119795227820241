import React, { useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import adminService from "../../services/admin.service";

export default function AllProjects() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 8;
  const [sortField, setSortField] = useState('');

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const handleSortChange = (e) => {
    setSortField(e.target.value);
  };

  const sortedProjects = [...projects].sort((a, b) => {
    if (sortField) {
      if (a[sortField] < b[sortField]) return -1;
      if (a[sortField] > b[sortField]) return 1;
    }
    return 0;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchProjects = async () => {
      setLoading(true);
      const response = await adminService.getAllProjects();
      if (response && response.data) {
        const sortedProjects = response.data.sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        setProjects(sortedProjects);
      } else {
        console.error('Failed to fetch projects, response or response.data is undefined');
      }
      setLoading(false);
    };
    fetchProjects();
  }, []);

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
    setCurrentPage(1); 
  };

  const filteredProjects = projects.filter((project) => {
    if (currentFilter === 'All') return true;
    return project.project_status === currentFilter; 
  });
  
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject); 

  const tabs = [
    { name: 'All', current: currentFilter === 'All', onClick: () => handleFilterChange('All') },
    { name: 'Active', current: currentFilter === 'Active', onClick: () => handleFilterChange('Active') },
    { name: 'Inactive', current: currentFilter === 'Inactive', onClick: () => handleFilterChange('Inactive') },
  ];

  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  const handlePreviousClick = () => {
    setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, totalPages));
  };

  const startResult = (currentPage - 1) * projectsPerPage + 1;
  const endResult = Math.min(startResult + projectsPerPage - 1, filteredProjects.length);

  return (
    <div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-2">
        <nav className="sm:hidden mt-5" aria-label="Back">
          <Link to="/home" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Home
          </Link>
        </nav>
        <nav className="hidden sm:flex mt-5" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <Link to="/home" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Home
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <Link to="/projects" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  Projects
                </Link>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 mt-5">
        <div className="rounded-lg bg-white px-5 py-6 shadow-lg border border-gray-100 sm:px-6">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-xl font-semibold text-gray-900 my-4">All Projects</h3>
          </div>
          <div className="flex min-h-full flex-col">
            <div className="px-0">
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 table-fixed">
                      <thead>
                        <tr>
                          <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Project ID</th>
                          <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Company</th>
                          <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Stage</th>
                          <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {currentProjects.length > 0 ? (
                          currentProjects.map((project) => (
                            <tr key={project.id} className="even:bg-gray-50">
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">{project.id}</td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate">{project.company_name}</td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
                                    project.project_stage !== 'Archived' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                  }`}>
                                  {project.project_stage}
                                </span>
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                <Link to={"/admin/projects/" + project.id} className="text-blue-500 hover:text-blue-700">
                                  Manage
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">No Projects Yet</td>
                          </tr>
                        )}
                        {currentProjects.length < projectsPerPage && [...Array(projectsPerPage - currentProjects.length)].map((_, i) => (
                          <tr key={`empty-${i}`} className="even:bg-gray-50">
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <nav
                      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                      aria-label="Pagination"
                    >
                      <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                          Showing <span className="font-medium">{startResult}</span> to <span className="font-medium">{endResult}</span> of{' '}
                          <span className="font-medium">{filteredProjects.length}</span> results
                        </p>
                      </div>
                      <div className="flex flex-1 justify-between sm:justify-end">
                        <button
                          onClick={handlePreviousClick}
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Previous
                        </button>
                        <button
                          onClick={handleNextClick}
                          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Next
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { ChartPieIcon, FingerPrintIcon, CursorArrowRaysIcon, SquaresPlusIcon, PlayCircleIcon, PhoneIcon, RectangleGroupIcon } from '@heroicons/react/24/outline';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const footerLinks = [
  {
    category: "Explore",
    links: [
      { name: "Solar", description: "Get a better understanding why going solar makes sense for your business", href: "/solar", icon: ChartPieIcon },
      { name: "Services", description: "Discover the Sunin business model, how we offer financing, and vetted installers", href: "services", icon: FingerPrintIcon },
      { name: "Mssion", description: "Dive into the features and functionality offered through the Sunin Platform", href: "/mission", icon: CursorArrowRaysIcon },
      { name: "Leadership", description: "Learn about the Sunin Founder team, and why we started Sunin Energy", href: "/team", icon: SquaresPlusIcon },
    ],
  },
  {
    category: "Get Involved",
    links: [
      // { name: "Request a Quote", href: "#", icon: PlayCircleIcon },
      { name: "Contact Us", href: "/contact", icon: PhoneIcon },
      // { name: "Become an Install Partner", href: "#", icon: RectangleGroupIcon },
    ],
  },
];

const socialLinks = [
  // { name: 'Facebook', href: '#', icon: FaFacebookF },
  // { name: 'Twitter', href: '#', icon: FaTwitter },
  // { name: 'Instagram', href: '#', icon: FaInstagram },
  // { name: 'LinkedIn', href: '#', icon: FaLinkedinIn },
];

export default function Footer() {
  return (
    <footer className="bg-white text-gray-600 border border-top">
      <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:py-10 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="xl:col-span-1">
            <a href="/" className="flex items-center justify-center md:justify-start">
              <img className="h-16" src="/suninn_logo.png" alt="Company logo" />
              <span className="ml-3 text-xl font-bold">Sunin Energy</span>
            </a>
            <p className="mt-4 text-sm leading-6">
              Empowering the future with sustainable solar energy solutions.
            </p>
            <div className="flex mt-4 justify-center md:justify-start">
              {socialLinks.map((link) => (
                <a key={link.name} href={link.href} className="text-gray-400 hover:text-gray-500 ml-4 first:ml-0">
                  <span className="sr-only">{link.name}</span>
                  <link.icon className="h-5 w-5" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            {footerLinks.map((section) => (
              <div key={section.category}>
                <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider">
                  {section.category}
                </h3>
                <ul className="mt-4 space-y-3">
                  {section.links.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm hover:text-gray-900">
                        <item.icon className="mr-2 h-4 w-4 text-gray-400" aria-hidden="true" />
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-6">
          <p className="text-xs text-gray-700 text-center">
            &copy; {new Date().getFullYear()} Sunin Energy. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

import React, { useState } from 'react';

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMoreShown, setReadMoreShown] = useState(false);

  const toggleReadMore = () => {
    setReadMoreShown(!isReadMoreShown);
  };

  return (
    <p className="text-base leading-7 text-gray-600">
      {isReadMoreShown ? text : `${text.substring(0, 250)}...`}
      <button onClick={toggleReadMore} className="text-blue-500 ml-2">
        {isReadMoreShown ? 'Read Less' : 'Read More'}
      </button>
    </p>
  );
};


export default function Team() {
    const people = [
        {
          name: 'Christopher Perez',
          role: 'Chief Executive Officer & Founder',
          imageUrl:
            '/images/ChrisP.jpeg',
          bio: "With over six years of experience in residential and commercial Solar and Battery-Storage, Christopher recognized the demand for a commercial fulfillment platform. Having observed transformative changes in residential solar through platform technology, he aims to bring his passion, service-oriented mindset, and brand integrity from the residential space to this commercial platform. Christopher identified the necessity for seasoned entrepreneurs to receive training and proper fulfillment in the commercial sector. In 2021, he joined a National residential platform and swiftly became a top producer. Adding to his expertise, Christopher boasts over 20 years of experience in the Food and Beverages Industry, where he played a crucial role as a Regional Manager for McDonald's Corporation in the Asia-Pacific region during the 1990s. He holds a B.A. Degree in Accounting and Finance from Letran College in the Philippines.",
        },
        {
            name: 'Jay Lee',
            role: 'Chief Financial Officer & Co-Founder',
            imageUrl:
              'images/jayHeadshot.jpg',
            bio: "Jay brings a distinctive professional background encompassing private equity investment, commercial real estate and finance, and corporate management. Armed with diverse experiences and direct access to the capital market, Jay co-founded SUNIN. In the realm of private equity, he managed Amstar.io, Inc. a boutique investment and consulting firm which partnered with a family office and private equity firms in Korea. His commercial real estate and finance experience comes from operating real estate and finance companies in California for over 20 years. He had orchestrated deals over 180M+ and arranged a $60M mixed-use development project in the US from Korea Capital. Jay also contributed to Fortune 500 companies like Bank of America, Johnson Controls, and Westinghouse Electric Corporation in various junior and senior positions. Jay holds a B.S. Degree in Mechanical Engineering from the University of Washington.",
          },
          {
            name: 'Colby West',
            role: 'Chief Operating Officer & Co-Founder',
            imageUrl:
              'images/colbyHeadshot.png',
            bio: "Since 2015, Colby has immersed himself in the renewable energy sector, dedicating four years to engineering with SolarCity/Tesla, followed by another four years focused on sales, business development, and finance. As the Chief Business Development Officer (CBDO) of a previous startup, he gained invaluable insights into both the engineering and operational facets of renewable energy. This dual perspective equips Colby with a comprehensive understanding of the technical aspects of solar energy and the essential elements of a successful team. With a knack for cultivating relationships, Colby excels in creating mutually beneficial scenarios with open communication for ongoing enhancement. A recognized market leader and product expert in residential solar, Colby is passionate about training and sharing industry knowledge. He earned B.S. Degrees in both Electrical Engineering Technology and Computer Engineering Technology from Minnesota State University, Mankato.",
          },
          {
            name: 'Morgan Cooper',
            role: 'Director of Technology & Co-Founder',
            imageUrl: 
              'images/morganHeadshot.png',
            bio: "Morgan brings a unique blend of technical prowess and seasoned leadership as the Director of Technology, with a self-taught background as a full-stack software engineer. His journey reflects a proven track record across various sectors, boasting over six years of extensive experience in sales positions within both the technology and solar industries. His skill set ranges from networking and door-to-door sales to cold calling, closing deals, and account management complemented by his adeptness in building and guiding effective sales processes. Additionally, Morgan's understanding of neurolinguistic programming and human performance coaching enriches his insight into team dynamics and communication. Currently expanding his academic horizon with a bachelor's degree in Computer Science and a master's degree in Software Engineering from Regis University, Morgan is continuously enhancing his technical and leadership capabilities. His comprehensive skill set and forward-thinking approach make him a formidable force in driving innovation and operational excellence in any technologically advanced environment.",
          },
          {
            name: 'Lady May Panelo',
            role: 'Director of Health and Wellness & Co-Founder',
            imageUrl: 
              'images/ladyMayHeadshot.jpg',
            bio: "Lady May Panelo, FNP-C, is a highly skilled Primary Care Health Practitioner (PCP) located in Las Vegas, Nevada. With her expertise in cardiology and internal medicine, she brings a wealth of knowledge to her role as a Health and Wellness Director for Sunin Energy. Lady May will actively oversee the health and vitality of our executive teams and selected Sunin Pros. Her dedication extends to providing telemedicine services, ensuring prompt and personalized care for acute medical issues through scheduled appointments. She will act as an adjunct PCP for our team.",
          },
      ]
      

      return (
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our leadership</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We’re a dynamic group of individuals who are passionate about what we do and dedicated to delivering the
                best results our SUNIN members.
              </p>
            </div>
            <ul
              className="mx-auto mt-20 grid grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none"
            >
              {people.map((person) => (
                <li key={person.name} className="flex flex-col xl:flex-row gap-6">
                  <div className="flex-none" style={{ width: '208px', height: '260px' }}>
                    <img className="w-full h-full object-cover rounded-2xl" src={person.imageUrl} alt="" />
                  </div>
                  <div className="flex-auto">
                    <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                    <p className="text-base leading-7 text-gray-600">{person.role}</p>
                    <ReadMore>{person.bio}</ReadMore>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    }

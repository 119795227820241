import http from "../http-common";
import serviceUtil from "../utils/service-util"; // Ensure the import path is correct

class AccountService {
  async newAccount(data) {
    return await http.post("/accounts", { account: data });
  }

  async updateAccount(data) {
    const id = serviceUtil.extrapolateFinalIdFromUrl();
    return http.patch(`/accounts/${id}`, { account: data });
  }

  async getAccounts() {
    return await http.get("/accounts");
  }

  async getAccount(_id) {
    const id = _id || ( serviceUtil.extrapolateFinalIdFromUrl());
    return http.get(`/accounts/${id}`);
  }

  async createCheckoutSession() {
    return http.post("/create-checkout-session");
  }

  async getSetupIntent() {
    return http.post("/create-setup-intent");
  }

  async unsubscribeUser() {
    return http.patch("/unsubscribe");
  }

  async reinstateUser() {
    return http.patch("/reinstate");
  }

  async checkAccountActivation() {
    return http.get(`/check_sales_partner_subscription`);
  }
}

const accountService = new AccountService();
export default accountService;

import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import 'react-toastify/dist/ReactToastify.css';
import accountService from "../../services/accounts.service";

/**
 * Modal component to edit account information.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.isModalOpen - State to control the modal visibility.
 * @param {Function} props.setIsModalOpen - Function to set the modal visibility.
 * @param {Object} props.account - Account information to edit.
 * @param {Function} props.onAccountUpdated - Callback function when the account is updated.
 */
export default function EditAccountModal({ isModalOpen, setIsModalOpen, account, onAccountUpdated }) {
  const cancelButtonRef = useRef(null);
  const [companyName, setCompanyName] = useState(account.company_name);
  const [accountStatus, setAccountStatus] = useState(account.account_status);
  const [industry, setIndustry] = useState(account.industry);

  /**
   * Submits the updated account information to the server.
   *
   * @param {Object} data - The updated account data.
   */
  const submitUpdatedAccountInfo = async (data) => {
    try {
      await accountService.updateAccount(data);
      setIsModalOpen(false);
      onAccountUpdated();
    } catch (error) {
      console.error("Error updating the account:", error);
    }
  };

  /**
   * Handles form submission to update account information.
   *
   * @param {Event} e - The form submission event.
   */
  const handleUpdatedAccount = (e) => {
    e.preventDefault();
    const data = {
      account_status: accountStatus,
      company_name: companyName,
      industry: industry,
    };
    submitUpdatedAccountInfo(data);
  };

  // Update state when the account prop changes
  useEffect(() => {
    setCompanyName(account.company_name);
    setAccountStatus(account.account_status);
    setIndustry(account.industry);
  }, [account]);

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" initialFocus={cancelButtonRef} onClose={setIsModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-xl sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {account.company_name}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Update the fields below to update {account.company_name}'s information. Please ensure all information is accurate and up-to-date to maintain an effective project management processes.
                      </p>
                    </div>
                  </div>
                </div>

                <form onSubmit={handleUpdatedAccount}>
                  <div className="border-b border-gray-900/10 pb-12">
                    <div className="mt-10 mx-auto max-w-xl">

                      <div>
                        <label htmlFor="company_name" className="block text-sm font-medium leading-6 text-gray-900">
                          Company name
                        </label>
                        <div className="mt-2">
                          <input 
                            type="text"
                            name="company_name"
                            id="company_name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                                      ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                                      focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="industry" className="block text-sm font-medium leading-6 text-gray-900">
                          Industry
                        </label>
                        <div className="mt-2">
                          <select
                            id="industry"
                            name="industry"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                                      ring-inset ring-gray-300 focus:ring-2 focus:ring-inset 
                                      focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6"
                            value={industry}
                            onChange={(e) => setIndustry(e.target.value)}
                          >
                            <option value="">Select...</option>
                            <option value="Agriculture">Agriculture</option>
                            <option value="Auto">Auto</option>
                            <option value="Construction">Construction</option>
                            <option value="Education">Education</option>
                            <option value="Energy">Energy</option>
                            <option value="Finance">Finance</option>
                            <option value="Healthcare">Healthcare</option>
                            <option value="Hospitality">Hospitality</option>
                            <option value="Information Technology">Information Technology</option>
                            <option value="Manufacturing">Manufacturing</option>
                            <option value="Real Estate">Real Estate</option>
                            <option value="Retail">Retail</option>
                            <option value="Telecommunications">Telecommunications</option>
                            <option value="Transportation">Transportation</option>
                            <option value="Utilities">Utilities</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="account_status" className="block text-sm font-medium leading-6 text-gray-900">
                          Account Status
                        </label>
                        <div className="mt-2">
                          <select
                            id="account_status"
                            name="account_status"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                                      ring-inset ring-gray-300 focus:ring-2 focus:ring-inset 
                                      focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6"
                            value={accountStatus}
                            onChange={(e) => setAccountStatus(e.target.value)}
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 
                                text-sm font-semibold text-white shadow-sm hover:bg-blue-500 
                                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                                focus-visible:outline-blue-600 sm:col-start-2"
                    >
                      Update Account
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm 
                                font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                hover:bg-yellow-300 sm:col-start-1 sm:mt-0"
                      onClick={() => setIsModalOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import authenticationService from '../../services/authorization-services/auth.service'; 

const ActivationPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAccountActivated, setIsAccountActivated] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const cancelFetch = useRef(false);
  const messages = [
    "Did you know? One hour of sunlight received by Earth could meet the global energy needs for an entire year!",
    "Fun fact: Solar energy is the cleanest and most abundant renewable energy source available.",
    "Interesting: The largest solar power plant in the world is located in the Bhadla Solar Park in India, covering 14,000 acres!",
    "Solar tip: Solar panels don't need direct sunlight to produce electricity, they can also generate power on cloudy days.",
    "Eco-friendly: Investing in solar energy can significantly reduce your carbon footprint. Every bit helps in fighting climate change!",
  ];

  useEffect(() => {
    const startTime = Date.now();
    let messageIndex = 0;
    const changeMessage = () => setMessage(messages[messageIndex++ % messages.length]);
    const messageInterval = setInterval(changeMessage, 3000);

    const fetchProfile = async () => {
      if (cancelFetch.current || isTimeout) return; // Stop if flagged for cancellation or if timeout has occurred
      
      try {
        const response = await authenticationService.getUserProfile();
        if (response.account_type === "sales_partner" && response.subscription_status === "active") {
          const elapsedTime = Date.now() - startTime;
          clearInterval(messageInterval);
          const delay = Math.max(6000 - elapsedTime, 0);
          setTimeout(() => {
            if (cancelFetch.current || isTimeout) return; // Also check before setting state
            setIsAccountActivated(true);
            setIsLoading(false);
          }, delay);
        } else {
          setTimeout(fetchProfile, 3000);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setIsLoading(false);
      }
    };

    fetchProfile();

    const delayTimeout = setTimeout(() => {
      setIsTimeout(true);
      setIsLoading(false);
      cancelFetch.current = true;
    }, 30000); 

    return () => {
      clearInterval(messageInterval);
      clearTimeout(delayTimeout);
      cancelFetch.current = true;
    };
  }, [isTimeout]);

  const handleContinue = () => {
    navigate("/home");
  };

  return (
    <>
      <style>
        {`
          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 80vh; /* Minimum height of the container */
          }
          .spinner-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
          }
          .spinner {
            border: 4px solid rgba(0, 0, 0, 0.1);
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border-left-color: #09f;
            animation: spin 1s linear infinite;
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          .celebration-message {
            opacity: 0;
            transition: opacity 2s ease-out;
          }
          .visible {
            opacity: 1;
          }
          .max-w-3xl {
            width: 90%; /* Responsive base width */
            max-width: 800px; /* Maximum width */
          }
          @media (min-width: 768px) { /* Larger devices */
            .max-w-3xl {
              width: 75%; /* Adjust width on larger screens */
            }
          }
          @media (min-width: 1024px) { /* Large desktops and laptops */
            .max-w-3xl {
              width: 50%; /* Adjust width on very large screens */
            }
          }
        `}
      </style>

      <div className="container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto text-center">
          <div className="bg-white shadow-lg rounded-lg p-8">
            <h2 className="text-2xl font-semibold mb-4">
            {isTimeout && !isAccountActivated  ? "Activation Error" : 
              isLoading ? "Activation in Progress" : 
              isAccountActivated ? "Activation Successful!" : "Error in Activation"}
            </h2>
            {isLoading && !isTimeout ? (
              <>
                <p className="mb-4">{message}</p>
                <div className="spinner-container"><div className="spinner"></div></div>
              </>
            ) : isTimeout && !isAccountActivated  ? (
              <p className="text-red-700">
                Sorry, this is taking longer than expected. 
                Please reach out to our customer support 
                team at techsupport@suninenergy.com
              </p>
            ) : (
              <div className="celebration-message visible">
                <h2 className="text-3xl font-semibold text-green-500">Congratulations!</h2>
                <p className="text-xl">Your account is now active and ready to go!</p>
              </div>
            )}
            <hr className="my-6 border-gray-300" />
            {isAccountActivated && (
              <button onClick={handleContinue} className="mt-4 py-2 px-4 rounded-lg bg-blue-500 text-white">
                Continue to Your New Platform!
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivationPage;

import React, { useState, useEffect } from 'react';
import NewContactModal from './NewContact.component';
import ViewContactModal from './ViewContact.component';
import projectService from '../../services/projects.service';
import { TrashIcon, EyeIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

export default function ProjectContacts({ projectId }) {
  const [isViewContactModalOpen, setIsViewContactModalOpen] = useState(false);
  const [isNewContactModalOpen, setIsNewContactModalOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [contactId, setSelectedContactId] = useState(null);

  const fetchContacts = async () => {
    try {
      const response = await projectService.getContacts(projectId);
      setContacts(response.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const deleteContact = async (contactId) => {
    try {
      await projectService.deleteContact(contactId);
      const updatedContacts = contacts.filter(contact => contact.id !== contactId);
      setContacts(updatedContacts);
      toast.success('Contact deleted successfully!');
    } catch (error) {
      console.error("Error deleting contact:", error);
      toast.error('Failed to delete contact.');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchContacts();
  }, []);

  return (
    <>
      <NewContactModal 
        isNewContactModalOpen={isNewContactModalOpen}
        setIsNewContactModalOpen={setIsNewContactModalOpen}
        projectId={projectId}
        fetchContacts={fetchContacts}
        toast={toast}
      />

      <ViewContactModal
        isViewContactModalOpen={isViewContactModalOpen} 
        setIsViewContactModalOpen={setIsViewContactModalOpen}
        contactId={contactId}
        fetchContacts={fetchContacts}
        toast={toast}
      />

      <div className="flex justify-center mt-5">
        <div className="w-full max-w-7xl">
          <div className="rounded-lg bg-white px-4 py-4 shadow-md border border-gray-100">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold text-gray-700 py-2">Project Contacts</h2>
              
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none"
                  onClick={() => setIsNewContactModalOpen(true)}
                >
                  Add Contact
                </button>
              
            </div>
            <div className="mt-5 h-128 overflow-y-auto">
              {contacts.length > 0 && (
                <div className="space-y-4 overflow-y-auto ">
                  {contacts.map((contact, index) => (
                    <div key={index} className="flex items-center bg-white shadow-md rounded-lg overflow-hidden border border-gray-300 hover:shadow-lg transition-shadow duration-200 ease-in-out p-1">
                      <div className="flex-shrink-0 mr-4">
                        {contact.profile_photo_url ? (
                          <img className="w-14 h-14 object-cover rounded-full" src={contact.profile_photo_url} alt={`${contact.contact_first_name} ${contact.contact_last_name}`} />
                        ) : (
                          <UserCircleIcon className="w-14 h-14 text-gray-400" />
                        )}
                      </div>
                      <div className="flex-grow">
                        <h5 className="text-sm font-semi-bold text-gray-800 truncate">{contact.contact_first_name} {contact.contact_last_name}</h5>
                        <p className="text-sm text-gray-500">{contact.contact_title}</p>
                      </div>
                      <div className="hidden md:flex-grow md:max-w-xs md:block">
                        <p className="text-sm text-gray-900">{contact.contact_email}</p>
                        <p className="text-sm text-gray-900">{contact.contact_phone_number}</p>
                      </div>
                      <div className="flex space-x-4 ml-auto">
                        <button
                          onClick={() => {
                            setSelectedContactId(contact.id);
                            setIsViewContactModalOpen(true);
                          }}
                          className="btn-view flex items-center text-blue-600 hover:text-blue-700 transition-colors duration-150 ease-in-out"
                        >
                          <EyeIcon className="w-6 h-6 md:h-4 w-4 mr-2" />
                          <span className="hidden md:inline">View</span>
                        </button>
                        <button
                          onClick={() => deleteContact(contact.id)}
                          className="btn-delete flex items-center text-red-500 hover:text-red-600 transition-colors duration-150 ease-in-out"
                        >
                          <TrashIcon className="w-6 h-6 md:h-4 md:w-4" />
                          <span className="hidden md:inline mx-2">Delete</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

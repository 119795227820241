import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const proposalStatus = [
  'Proposal requested and pending review',
  'Proposal accepted and assigned to engineer',
  'Proposal created and attached to project',
  'Proposal request rejected',
  'Revision requested and pending review',
  'Revision request accepted and assigned to engineer',
  'Revision completed and attached to project',
  'Revision request rejected',
];

export default function ProposalTicketCardWithAdminModal({
  isOpen,
  onClose,
  proposalTicket,
  formatDateTime,
  openRevisionForm,
  showRevisionForm,
  selectedTicketId,
  setShowRevisionForm,
  handleRequestRevision,
  dispatch,
  formState,
  setRevisionDetails,
  setRevisionType,
  revisionDetails,
  revisionType,
  details,
  setDetails,
  newStage,
  setNewStage,
  rejectionReason,
  setRejectionReason,
  rejectionSpecific,
  setRejectionSpecific,
  engineerName,
  setEngineerName,
  proposalLinkTitle,
  setProposalLinkTitle,
  proposalLinkUrl,
  setProposalLinkUrl,
  handleUpdateProposal,
  loading,
  missingItems,
  project
}) {
  const resetForm = () => {
    setNewStage('');
    setDetails('');
    setRejectionReason('');
    setRejectionSpecific('');
    setEngineerName('');
    setProposalLinkTitle('');
    setProposalLinkUrl('');
  };

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  const handleUpdate = async () => {
    await handleUpdateProposal();
    resetForm();
    onClose();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case proposalStatus[0]:
        return 'bg-gray-300';
      case proposalStatus[1]:
        return 'bg-gray-500';
      case proposalStatus[2]:
        return 'bg-blue-500';
      case proposalStatus[3]:
        return 'bg-red-600';
      case proposalStatus[4]:
        return 'bg-gray-300';
      case proposalStatus[5]:
        return 'bg-gray-500';
      case proposalStatus[6]:
        return 'bg-blue-500';
      case proposalStatus[7]:
        return 'bg-red-600';
      default:
        return 'bg-gray-600';
    }
  };

  const getBarWidth = (status) => {
    switch (status) {
      case proposalStatus[0]:
        return 'w-1/3';
      case proposalStatus[1]:
        return 'w-2/3';
      case proposalStatus[2]:
        return 'w-full';
      case proposalStatus[3]:
        return 'w-full';
      case proposalStatus[4]:
        return 'w-1/2';
      case proposalStatus[5]:
        return 'w-3/4';
      case proposalStatus[6]:
        return 'w-full';
      case proposalStatus[7]:
        return 'w-full';
      default:
        return 'w-full';
    }
  };

  const handleRequestRevisionToggle = () => {
    if (showRevisionForm && selectedTicketId === proposalTicket.id) {
      setShowRevisionForm(false);
      setRevisionDetails('');
      setRevisionType('');
    } else {
      openRevisionForm(proposalTicket.id);
    }
  };

  const canRequestRevision = (status) => {
    const stagesWithRevisionButton = [
      'Revision completed and attached to project',
      'Revision rejected',
      'Proposal created and attached to project',
      'Proposal request rejected'
    ];
    return stagesWithRevisionButton.includes(status);
  };

  const showProposalLink = (status) => {
    const stagesWithLink = [
      'Revision completed and attached to project',
      'Proposal created and attached to project'
    ];
    return stagesWithLink.includes(status);
  };

  return (
    <>
      <style>
        {`
          select option {
            white-space: normal;
          }
        `}
      </style>

      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleCancel}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-6">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full max-w-lg sm:max-w-3xl sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 sm:ml-4 sm:mt-0 w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 mb-4">
                        Manage Proposal
                      </Dialog.Title>

                      <div className="mt-2">
                        {/* Admin Functionalities */}
                        <div className="space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">New Stage</label>
                            <select
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              value={newStage}
                              onChange={(e) => setNewStage(e.target.value)}
                            >
                              <option value="">Select a stage</option>
                              {proposalStatus.map((stage) => (
                                <option key={stage} value={stage}>
                                  {stage}
                                </option>
                              ))}
                            </select>
                          </div>
                          {newStage === 'Proposal accepted and assigned to engineer' && (
                            <div>
                            <label className="block text-sm font-medium text-gray-700">Engineer's Name</label>
                            <select
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              value={engineerName}
                              onChange={(e) => setEngineerName(e.target.value)}
                            >
                              <option value="">Select an engineer</option>
                              {/* Add options here */}
                              <option value="Saurav Kumar">Saurav Kumar</option>
                            
                              {/* Continue adding engineer options as needed */}
                            </select>
                          </div>
                          )}
                          {['Proposal request rejected', 'Revision rejected'].includes(newStage) && (
                            <>
                              <div>
                                <label className="block text-sm font-medium text-gray-700">Rejection Reason</label>
                                <select
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                  value={rejectionReason}
                                  onChange={(e) => setRejectionReason(e.target.value)}
                                >
                                  <option value="">Select a reason</option>
                                  <option value="incorrect information">Incorrect Information</option>
                                  <option value="missing information">Missing Information</option>
                                  <option value="not feasible">Not Feasible</option>
                                  <option value="out of scope">Out of Scope</option>
                                </select>
                              </div>
                              {['incorrect information', 'missing information'].includes(rejectionReason) && (
                                <div>
                                  <label className="block text-sm font-medium text-gray-700">Rejection Specific</label>
                                  <select
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={rejectionSpecific}
                                    onChange={(e) => setRejectionSpecific(e.target.value)}
                                  >
                                    <option value="">Select a specific field</option>
                                    <option value="utility_company">Utility Company</option>
                                    <option value="meter_number">Meter Number</option>
                                    <option value="number_of_meters">Number of Meters</option>
                                    <option value="main_panel_amperage">Main Panel Amperage</option>
                                    <option value="power_bill_received">Power Bill Received</option>
                                    <option value="monthly_kilowatt_amount">Monthly Kilowatt Amount</option>
                                    <option value="annual_kilowatt_amount">Annual Kilowatt Amount</option>
                                    <option value="average_monthly_power_bill_cost">Average Monthly Power Bill Cost</option>
                                    <option value="type_of_roof">Type of Roof</option>
                                    <option value="age_of_roof">Age of Roof</option>
                                    <option value="address_1">Address Line 1</option>
                                    <option value="city">City</option>
                                    <option value="state">State</option>
                                    <option value="zip">Zip</option>
                                    <option value="company_name">Company Name</option>
                                    <option value="utility_account_holder">Utility Account Holder</option>
                                    <option value="utility_account_number">Utility Account Number</option>
                                    <option value="shade_interference">Shade Interference</option>
                                  </select>
                                </div>
                              )}
                            </>
                          )}
                          {newStage === 'Revision request accepted and assigned to engineer' && (
                            <div>
                              <label className="block text-sm font-medium text-gray-700">Engineer's Name</label>
                              <select
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={engineerName}
                                onChange={(e) => setEngineerName(e.target.value)}
                              >
                                <option value="">Select an engineer</option>
                                {/* Add options here */}
                                <option value="Saurav Kumar">Saurav Kumar</option>
                              
                                {/* Continue adding engineer options as needed */}
                              </select>
                            </div>
                          )}
                          {['Proposal created and attached to project', 'Revision completed and attached to project'].includes(newStage) && (
                            <>
                              <div>
                                <label className="block text-sm font-medium text-gray-700">Proposal Link Title</label>
                                <input
                                  type="text"
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                  value={proposalLinkTitle}
                                  onChange={(e) => setProposalLinkTitle(e.target.value)}
                                />
                              </div>
                              <div>
                                <label className="block text-sm font-medium text-gray-700">Proposal Link URL</label>
                                <input
                                  type="text"
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                  value={proposalLinkUrl}
                                  onChange={(e) => setProposalLinkUrl(e.target.value)}
                                />
                              </div>
                            </>
                          )}
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Details/Comments</label>
                            <textarea
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              rows="3"
                              value={details}
                              onChange={(e) => setDetails(e.target.value)}
                            ></textarea>
                          </div>
                          <div className="flex flex-col sm:flex-row justify-between">
                            <button
                              onClick={handleCancel}
                              className="mb-2 sm:mb-0 sm:mr-2 inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleUpdate}
                              disabled={loading}
                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              Update Proposal
                            </button>
                          </div>
                        </div>
                        {/* Proposal Ticket Details */}
                        <div className="relative rounded-lg bg-white p-6 shadow-md border border-gray-200 mt-6">
                          <h5 className="text-lg font-semibold text-gray-800 mb-4">{proposalTicket.title}</h5>
                          <p className="text-gray-700 mb-2"><strong>Company Name:</strong> {proposalTicket.company_name}</p>
                          <p className="text-gray-700 mb-2"><strong>Company Address:</strong> {proposalTicket.company_address}</p>
                          <p className="text-gray-700 mb-2"><strong>Current Status:</strong> {proposalTicket.status}</p>
                          <div className="mt-4">
                            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                              <div className={`h-2.5 rounded-full ${getStatusColor(proposalTicket.status)} ${getBarWidth(proposalTicket.status)}`}></div>
                            </div>
                          </div>
                          {canRequestRevision(proposalTicket.status) && !showRevisionForm && selectedTicketId !== proposalTicket.id && (
                            <div className="absolute top-4 right-4">
                              <button
                                className="bg-gray-400 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                                onClick={handleRequestRevisionToggle}
                              >
                                Request Revision
                              </button>
                            </div>
                          )}
                          {showRevisionForm && selectedTicketId === proposalTicket.id && (
                            <div className="mt-4">
                              <div className="mb-4">
                                <label className="text-gray-700">Revision Type:</label>
                                <select
                                  className="block w-full rounded-md border border-gray-300 shadow-sm p-3"
                                  value={revisionType}
                                  onChange={(e) => setRevisionType(e.target.value)}
                                >
                                  <option value="">Select revision type</option>
                                  <option value="Request a separate proposal design">Request a separate proposal design</option>
                                  <option value="Request a revision to the original proposal">Request a revision to the original proposal</option>
                                </select>
                              </div>
                              <div className="mb-4">
                                <label className="text-gray-700">Revision Details:</label>
                                <textarea
                                  className="block w-full rounded-md border border-gray-300 shadow-sm p-3"
                                  rows="4"
                                  value={revisionDetails}
                                  onChange={(e) => setRevisionDetails(e.target.value)}
                                ></textarea>
                              </div>
                              <div className="flex justify-between">
                                <button
                                  type="button"
                                  className="mt-2 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                                  onClick={handleRequestRevisionToggle}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="mt-2 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                  onClick={handleRequestRevision}
                                >
                                  Submit Revision Request
                                </button>
                              </div>
                            </div>
                          )}
                          {/* Proposal History */}
                          <div className="px-4 py-6 sm:px-6 lg:px-8">
                            <nav className="flex flex-col" aria-label="History">
                              <ol role="list" className="space-y-6">
                                {proposalTicket.proposal_ticket_histories &&
                                  proposalTicket.proposal_ticket_histories
                                    .sort((a, b) => new Date(a.event_date) - new Date(b.event_date))
                                    .map((historyItem, idx) => (
                                      <li key={idx} className="relative">
                                        <div className="flex items-start space-x-4">
                                          <div className={`flex-shrink-0 w-4 h-4 rounded-full ${getStatusColor(historyItem.status)}`}></div>
                                          <div className="ml-4">
                                            <div className="text-sm font-semibold" style={{ color: getStatusColor(historyItem.status).replace('bg-', 'text-') }}>
                                              Stage: {historyItem.status}
                                            </div>
                                            <div className="text-sm text-gray-600">
                                              <strong>Date:</strong> {formatDateTime(historyItem.event_date)}
                                            </div>
                                            <div className="text-sm text-gray-600">
                                              <strong>Action by:</strong> {historyItem.action_taken_by}
                                            </div>
                                            {historyItem.rejection_reason && (
                                              <div className="text-sm text-gray-600">
                                                <strong>Rejection Reason:</strong> {historyItem.rejection_reason}
                                              </div>
                                            )}
                                            {historyItem.rejection_specific && (
                                              <div className="text-sm text-gray-600">
                                                <strong>Rejection Specific:</strong> {historyItem.rejection_specific}
                                              </div>
                                            )}
                                            <div className="text-sm text-gray-600">
                                              <strong>Details:</strong> {historyItem.notes}
                                            </div>
                                            {showProposalLink(historyItem.status) && historyItem.proposal_link && (
                                              <div className="text-sm text-blue-500 hidden sm:table-cell">
                                                <strong>Proposal Link:</strong> <a href={historyItem.proposal_link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{historyItem.proposal_link}</a>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        {idx < proposalTicket.proposal_ticket_histories.length - 1 && (
                                          <div className="ml-2 h-full border-l-2 border-gray-300"></div>
                                        )}
                                      </li>
                                    ))}
                              </ol>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
    </>
  );
}

import React from 'react';
import WebHeader from './WebHeader';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

const WebLayout = () => {
  return (
    <>
      <WebHeader />
      <main>
        <Outlet /> {/* Use Outlet here */}
      </main>
      <Footer />
    </>
  );
};

export default WebLayout;
